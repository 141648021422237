import { Button as AntdButton, message } from "antd"
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import React from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { AiOutlineGlobal } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import { FaDownload, FaNetworkWired } from "react-icons/fa";
import { MdWarning } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

import { ExtLink } from 'components/Bloc';
import { updateHeadLink } from "reducer/action/coreActions";

import almalinux from '../../assets/img/almalinux.png'
import blueprint from '../../assets/img/blue/blueprint-KS.png'
import cloudpanel from '../../assets/img/blue/cloudpanel.png'
import cpanel from '../../assets/img/blue/cpanel-logo.png'
import cyberpanel from '../../assets/img/blue/cyberpanel-logo.png'
import docker from '../../assets/img/blue/docker-logo.png'
import gitlab from '../../assets/img/blue/gitlab.png'
import jitsi from '../../assets/img/blue/jitsi.png'
import joomla from '../../assets/img/blue/joomla-logo.png'
import LibreNMS from '../../assets/img/blue/LibreNMS-2-logo.png'
import mariadb from '../../assets/img/blue/mariadb.png'
import minecraftBedrock from '../../assets/img/blue/minecraft-bedrock-logo.png'
import minecraft from '../../assets/img/blue/minecraft-logo.png'
import mongodb from '../../assets/img/blue/mongodb.png'
import netmaker from '../../assets/img/blue/Netmaker-logo.png'
import nextcloud from '../../assets/img/blue/nextcloud-logo.png'
import nginxproxymanager from '../../assets/img/blue/nginxproxymanager.png'
import odoo from '../../assets/img/blue/odoo.png'
import openvpn from '../../assets/img/blue/openvpn.png'
import plesk from '../../assets/img/blue/plesk-logo.png'
import portainer from '../../assets/img/blue/portainer.png'
import prometheus_grafana from '../../assets/img/blue/prometheus_grafana.png'
import pufferpanel from '../../assets/img/blue/pufferpanel.png'
import traefik from '../../assets/img/blue/traefik.png'
import Webmin from '../../assets/img/blue/Webmin-logo.png'
import wordpress from '../../assets/img/blue/wordpress-logo.png'
import centos from '../../assets/img/centos.png'
import debian from '../../assets/img/debian.png'
// import docker from '../../assets/img/docker.png'
import linux from '../../assets/img/linux.png'
import ubuntu from '../../assets/img/ubuntu.png'
import { InputPassAdd, InputTextAdd, LabelAdd, NetworkRender, PageTitle, Spiner } from '../../components/Bloc';
import BlueprintCart from '../../components/BlueprintCart'
import { Delete } from "../../components/Modals"
import { ModalConfirm } from "../../components/Modals";
import { Toast } from '../../components/Notify';
import { OkButton } from "../../components/OkButton"
import { TabSelector } from "../../components/TabSelector";
import UserMgt from '../../lib/user_managment';
import VPSapi from '../../lib/vps_service';
import Utils from '../../utils';
import { NetworkingVps } from './manage/NetworkingVps'
import RebuildVPS from './manage/RebuildVPS'
import StorageVps from './manage/StorageVps'
import UpgradeVps from './manage/UpgradeVps'

const { confirm } = Modal;

const imgs = {
	ubuntu,
	debian,
	almalinux,
	docker,
	linux,
	centos
}
const tab = [
	'ubuntu',
	'debian',
	'almalinux',
	'docker',
	'linux',
	'centos'
]




export default function ManageVps({ props }) {

	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [messageApi, contextHolder] = message.useMessage()
	const [server, setvps] = useState(null)
	const [onRun, setonRun] = useState({})
	const [errorScan, setError] = useState(false)

	const [updatingPassword, setUpdatingPassword] = useState(false)

	const [update, setUpdate] = useState({
		password: null,
		confirmation: null
	})

	const [scanObj, setScanresult] = useState(null)
	const [onGoingScan, setongoingscan] = useState(false)


	const [selectedTab, setSelectedTab] = useTabs([
		"Connect",
		"Networking",
		"Delete",
		"Rebuild",
		"Upgrade",
		"Blueprint",
		"Security",
		"Storage",
	]);


	const handleChange = (ev) => {
        const { name, value } = ev.target
        return setUpdate({...update, [name]: value})
    }



	const onUpdatePassword = async () => {
        try {
			console.log('update param', update)
			if(
				update.password && 
				(String(update.password).trim()).length >= 6 
			){
				if (Utils.testPassworsVps(update.password)) {
		            return messageApi.open({
		                type: 'error',
		                content: "Password should not contains empty space"
		            })
		        }
				if(update.password === update.confirmation){
					setUpdatingPassword(true)
					const data = {
						// action: "reset_password",
						password: update.password
					}
					const result = await UserMgt.onVpsAction("reset_password", server?.Id,  data)
					setUpdatingPassword(false)
					if(!result.error){
						messageApi.open({
							type: 'success',
							content: 'Password updated.',
						});
						await UserMgt.get_user_vps()
					}else{
						messageApi.open({
							type: 'error',
							content: 'An error occurs, please try again or contact us.',
						});
					}
				}else{
					messageApi.open({
						type: 'error',
						content: 'The new password field and confirmation field values should be the same.',
					});
					return
				}
			}else{
				messageApi.open({
					type: 'error',
					content: 'The new password must be at least 6 characters.',
				});
				return
			}
		} catch (error) {
			console.log('Update password error ', error)
		}
    }

	const loadVps = async () => {
		const vps = await UserMgt.getUniqueVps(id) //.user_vps
		if(!vps?.error){
			console.log('UNIQUE VPSr', vps?.server)
			setvps(vps?.server)
			return vps?.server
		}else{
			messageApi.open({
				type: 'error',
				content: 'An error occured. If this persist, please tell us',
			});
			return navigate(-1)
		}
	}
	async function onReboot() {
		setonRun({ ...onRun, reboot: true })
		const result = await UserMgt.onVpsAction("reboot", server?.Id)
		console.log('result onReboot', result)
		setonRun({...onRun, reboot: null })
		if (result && !result.error) {
			await loadVps()
			Toast.fire({
				icon: 'success',
				title: "Server " + server.OpenstackInstanceName + " rebooted"// result.details
			})
			// return navigate(-1)
		} else {

			Toast.fire({
				icon: 'error',
				title: result?.message || "An error occured, if this persist, please tell us"
			})

		}
	}
	async function onStop() {
		setonRun({...onRun, stop: true })

		const result = await UserMgt.onVpsAction("stop", server?.Id)
		console.log('result onStop', result)
		setonRun({...onRun,  stop: null })
		if (result && !result.error) {
			await UserMgt.get_user_vps()
			Toast.fire({
				icon: 'success',
				title: "Server " + server.OpenstackInstanceName + " stopped" //result.details
			})
			return navigate(-1)
		} else {

			Toast.fire({
				icon: 'error',
				title: result?.message || "An error occured, if this persist, please tell us"
			})

		}
	}
	async function onStart() {
		setonRun({...onRun, start: true })
		const result = await  UserMgt.onVpsAction("start", server?.Id)
		console.log('result onStop', result)
		setonRun({...onRun,  start: null })
		if (result && !result.error) {
			await UserMgt.get_user_vps()
			Toast.fire({
				icon: 'success',
				title: "Server " + server.OpenstackInstanceName + " started" //result.details
			})
			return navigate(-1)
		} else {

			Toast.fire({
				icon: 'error',
				title: result?.message || "An error occured, if this persist, please tell us"
			})

		}
	}
	
	
	useEffect(() => { 

        let headLinks = [];
		loadVps().then((vps) => {
			if(vps && vps.IsMiniSec){
				headLinks = [
					{
						link: "/compute/vpssecmini",
						item: 'vpssecmini',
						alt: "VPSminisec short link"
					}
				]
				if(Utils.getUrlParam('from-conect')){
					headLinks.push({link: '/compute/vps/connect/'+vps.Id, label: "Connect", arrow: true})
				}
				headLinks.push({label: vps?.DisplayName, arrow: true})
				
			}else{

				headLinks = [
					{
						link: "/compute/vps",
						item: 'vps',
						alt: "VPS short link"
					},
					{label: vps?.DisplayName, arrow: true},
				]
			}
			dispatch(updateHeadLink(headLinks))
				
		})
		
		if (Utils.getUrlParam('act') == "up") {
			setSelectedTab('Upgrade')
		}
		if (Utils.getUrlParam('act') == "s") {
			setSelectedTab('Storage')
		}
		return () => dispatch(updateHeadLink([]))
	}, [])


	const getNetwork = async (serv) => {
		try {
			const result = await UserMgt.getNetwork(serv?.Id)
			console.log('result network', result)
			if(result?.network) setvps({...serv, network: result.network})
		} catch (error) {
			console.log('Error get network', error)
		}
	}
	const onScan = async () => {
		setongoingscan(true)
		setError(false)

		const result = await VPSapi.scan(server?.OpenstackInstanceExtIp)
		if (!result?.ip) {
			setError(true)
			setongoingscan(false)
			return;
		}
		setongoingscan(false)
		setScanresult(result)
		console.log("result result onscan", result)
	}

	

	async function onDelete() {
		Utils.dispatchToDelete(server, '/compute/vps')
	}





	return (
		<>
			<div className="dark:text-darkTextColor pb-10">
				{contextHolder}
				<div className="container mx-auto max-w-full px-3 md:px-8">
					{server &&
						<div>
							<div className="flex gap-6 items-center px-2 md:px-0">
								<div className="w-1/12">
									<img src={imgs[tab.find(k => server?.OpenstackInstanceImageName?.toLowerCase().includes(k))]} alt="" className='w-20 h-20' />
								</div>
								<div className="w-11/12 ">
									<div>
										<span className="text-xl xl:text-2xl 2xl:text-3xl font-medium">{server?.DisplayName || server?.OpenstackInstanceName}</span> <br />
										<div className="flex justify-between border-b-2 border-slate-300 dark:border-gray-800 pb-3 pt-2">
											<div className="text-sm 2xl:text-base">
												<span>{server.type?.OpenstackFlavorName}</span> <br />
												<span>{server?.location?.name || "Montreal, Zone A"}</span> <br />
											</div>

											<div className="text-sm 2xl:text-base">
												<div className="flex gap-4">
													{server?.VpsStatus === "down" ?<OkButton loanding={onRun['start']} click server={server} fun={onStart} title={"Start"} /> : null}
													{server?.VpsStatus === "up" ?<OkButton loanding={onRun['stop']} click server={server} fun={onStop} title={"Stop"} />  : null}
													<OkButton loanding={onRun['reboot']} click server={server} fun={onReboot} title={"Reboot"} />
												</div>
												<p className="text-right pt-1">State: 
												<span className="font-bold">
													{server?.VpsStatus  === "up" ? " Running" : " Down"}
												</span>
												</p>
											</div>
										</div>
									</div>

									<div className="grid md:grid-cols-2">

										<div className="text-left">
											<p className="text-left pt-2 text-sm 2xl:text-base">
												Public Hostname: <span className="font-bold text-primary">
													{server?.domain || (server.OpenstackInstanceName + (server?.IsMiniSec ? ".vpssecmini" :".vps" )+".keepsec.cloud")}
													</span> <br />
												Private Hostname: <span className="font-bold  text-primary">
													{server.OpenstackInstanceName || "(Not defined)"}
												</span> <br />

											</p>
										</div>
										<div className="text-right text-sm 2xl:text-base">
											<p className="text-right pt-2">
												Public IP: <span className="font-bold">{server.OpenstackInstanceExtIp}</span> <br />
												VPC IP: 
												<span className="font-bold">
													<NetworkRender
														vpc={server?.vpc}
													/>
												</span> 
												<br />
												OS: <span className="font-bold">{server.OpenstackInstanceImageName}</span> <br />
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="">
								<ModalConfirm server={server} item="Portainer" />
								<ModalConfirm server={server} item="Traefik" />
								<ModalConfirm server={server} idx="MongoDB" item="MongoDB" />
								<ModalConfirm server={server} item="Netmaker" />
								<ModalConfirm server={server} item="MariaDB" />
								<ModalConfirm server={server} item="Nginx Proxy Manager" idx="NginxProxyManager" />
								<ModalConfirm server={server} idx="Cpanel" item="cPanel" />
								<ModalConfirm server={server} item="Cyberpanel" />
								<ModalConfirm server={server} item="Plesk" />
								<ModalConfirm server={server} item="Nextcloud" />
								<ModalConfirm server={server} item="Wordpress" />
								<ModalConfirm server={server} item="Joomla" />
								<ModalConfirm server={server} idx='MinecraftJava' item="Minecraft Java" />
								<ModalConfirm server={server} item="Webmin" />
								<ModalConfirm server={server} item="LibreNMS" />
								<ModalConfirm server={server} item="Pufferpanel" />
								<ModalConfirm server={server} item="Docker" />
								<ModalConfirm server={server} idx='MinecraftBedrock' item="Minecraft Bedrock" />
								<ModalConfirm server={server} item="OpenVPN" />
								<ModalConfirm server={server} item="Odoo" />
								<ModalConfirm server={server} item="CloudPanel" />
								<ModalConfirm server={server} item="Jitsi" />
								<ModalConfirm server={server} item="GitLab" />
								<ModalConfirm server={server} item="Prometheus & Grafana" idx="PrometheusGrafana" />

								<nav className="flex w-full overflow-y-auto  border-b border-gray-300 dark:border-gray-800">
									<TabSelector
										isActive={selectedTab === "Connect"}
										onClick={() => setSelectedTab("Connect")}
									>
										Connect
									</TabSelector>

									<TabSelector
										isActive={selectedTab === "Networking"}
										onClick={() => setSelectedTab("Networking")}
									>
										Networking
									</TabSelector>

									

									<TabSelector
										isActive={selectedTab === "Rebuild"}
										onClick={() => setSelectedTab("Rebuild")}
									>
										Rebuild
									</TabSelector>

									<TabSelector
										isActive={selectedTab === "Upgrade"}
										onClick={() => setSelectedTab("Upgrade")}
									>
										Upgrade
									</TabSelector>

									<TabSelector
										isActive={selectedTab === "Blueprint"}
										onClick={() => setSelectedTab("Blueprint")}
									>
										Blueprint
									</TabSelector>

									<TabSelector
										isActive={selectedTab === "Security"}
										onClick={() => setSelectedTab("Security")}
									>
										Security
									</TabSelector>
									<TabSelector
										isActive={selectedTab === "Storage"}
										onClick={() => setSelectedTab("Storage")}
									>
										Storage
									</TabSelector>
									
									<TabSelector
										isActive={selectedTab === "Delete"}
										onClick={() => setSelectedTab("Delete")}
									>
										Delete
									</TabSelector>


								</nav>

								<div className="pt-5">
									<TabPanel hidden={selectedTab !== "Connect"}>
										<div className="  flex justify-between flex-wrap gap-2 xl:gap-4 2xl:gap-6 w-full">
											<div className="w-full lg:w-1/2 border-b-2 md:border-b-0 pb-5">
												<div className="flex gap-2 items-center">
													<PageTitle text="Connect to your instances" />
													<BiHelpCircle className="text-blue-700 text-2xl" />
												</div>
												<p className="text-sm 2xl:text-base pt-2">You can connect using your browser or your own compatible SSH client</p>
												<p className="text-base 2xl:text-xl pt-4">
													Use your browser
													<br />
													<span className="text-sm 2xl:text-base">
														Connect using our browser-based SSH client
													</span>
													<br />
													<br />
													<Link to={"/compute/vps/" + server.Id + "/console"}>
														<OkButton title={"Connect using SSH"} />
													</Link>
													<br />
													<br />
													Use your own SSH client
													<br />
													<ExtLink 
														href={"#"}
														text="Connect using an  SSH client"
													/>
												</p>
												<div className="py-2 px-4 mt-5 border-2 rounded-md border-gray-300 w-full lg:w-2/3 shadow-lg">
													<span className=" text-sm 2xl:text-base">
														CONNECT TO
													</span>
													<br />
													<span className="text-base xl:text-xl 2xl:text-2xl font-bold">
														{server.OpenstackInstanceExtIp}
													</span>

													<br />
													<p className="text-sm 2xl:text-base mt-3">
														USER NAME
														<br />
														<span className="text-2xl font-bold">
															{server.SshUsername || "(not Defined)"}
														</span>
													</p>
												</div>
											</div>
											<div className="w-full pb-10 lg:w-[400px] 2xl:w-[500px] pt-2 md:pt-0">
												<div className="">
													<LabelAdd
														htmlFor="password"
														toLowerCase
														placeholder={"New password"}
													/>
													<InputPassAdd
														idx="password"
														value={update.password}
														name="password"
														onChange={handleChange}
														required
														disabled={updatingPassword}
														placeholder='Enter new password'
													/>
												</div>
												<div>
													<LabelAdd
														toLowerCase
														htmlFor="confirmation"
														placeholder={"New password confirmation"}
													/>
													<InputTextAdd
														idx="confirmation"
														type="password"
														value={update.confirmation}
														name="confirmation"
														onChange={handleChange}
														required
														disabled={updatingPassword}
														placeholder='Enter the new password confirmation'
													/>
												</div>
												<div className="pt-5">
													
														<AntdButton 
															onClick={onUpdatePassword} 
															size="large" 
															type="primary" 

															className=" w-56 flex items-center font-bold py-4 px-4 text-center justify-center" 
															loading={updatingPassword}>
															Change password
														</AntdButton>
												  	{/*</Popconfirm>*/}
												</div>
											</div>
										</div>
									</TabPanel>

									<TabPanel hidden={selectedTab !== "Networking"}>
										<div className=" ">
											<NetworkingVps onGetNetwork={getNetwork} server={server} loadVps={loadVps} />
										</div>
									</TabPanel>


									<TabPanel hidden={selectedTab !== "Delete"}>
										<div className="">

											<div className="flex gap-2 items-center">
											
												<PageTitle text="Delete this instance" />
											</div>
											<p className="text-sm 2xl:text-base pt-2">
												Deleting this instance will permanently destroy it including all of its data.

											</p>
											<p className="text-sm 2xl:text-base font-bold pt-2">
												Are you sure you want to delete this instance?
												<br />
												<br />
												<Delete id={"Delete" + server?.Id} server={server} />  
												<OkButton danger loanding={onRun['delete']} click server={server} fun={onDelete} title={"Delete"} />
											</p>
										</div>
									</TabPanel>


									<TabPanel hidden={selectedTab !== "Rebuild"}>
										<div className="">
											<RebuildVPS server_id={server?.OpenstackInstanceId} Id={server?.Id}/>

										</div>
									</TabPanel>

									<TabPanel hidden={selectedTab !== "Upgrade"}>
										<div className="">
											<UpgradeVps vpsId={id} loadVps={loadVps} server={server} />

										</div>
									</TabPanel>

									<TabPanel hidden={selectedTab !== "Blueprint"}>
										<div className="pb-10">

											<div className="flex gap-2 items-center " >
												<img src={blueprint} alt="" className='w-8 h-8 xl:w-12 xl:h-12 2xl:w-20 2xl:h-20' />
												<PageTitle text="Select a blueprint" />
												
											</div>

											<div className="w-11/12 pt-10 grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 gap-4">


												<BlueprintCart name="Cyberpanel" img={cyberpanel} id="Cyberpanel" version="Latest" />
												<BlueprintCart name="cPanel" img={cpanel} id="Cpanel" version="Latest" />
												<BlueprintCart name="Plesk" img={plesk} id="Plesk" version="Latest" />
												<BlueprintCart name="CloudPanel" img={cloudpanel} id="CloudPanel" version="Latest" />
												<BlueprintCart name="Webmin" img={Webmin} id="Webmin" version="Latest" />
												<BlueprintCart name="NextCloud" img={nextcloud} id="Nextcloud" version="Latest" />
												<BlueprintCart name="LibreNMS" img={LibreNMS} id="LibreNMS" version="Latest" />
												<BlueprintCart name="Netmaker" img={netmaker} id="Netmaker" version="Latest" />
												<BlueprintCart name="OpenVPN" img={openvpn} id="OpenVPN" version="Latest" />
												<BlueprintCart name="Wordpress" img={wordpress} id="Wordpress" version="Latest" />
												<BlueprintCart name="Joomla" img={joomla} id="Joomla" version="Latest" />
												<BlueprintCart name="Odoo" img={odoo} id="Odoo" version="Latest" />
												<BlueprintCart name="Minecraft Java" img={minecraft} id="MinecraftJava" version="Latest" />
												<BlueprintCart name="Minecraft Bedrock" img={minecraftBedrock} id="MinecraftBedrock" version="Latest" />
												<BlueprintCart name="Pufferpanel" img={pufferpanel} id="Pufferpanel" version="Latest" />
												<BlueprintCart name="Docker" img={docker} id="Docker" version="Latest" />
												<BlueprintCart name="Portainer" img={portainer} id="Portainer" version="Latest" />
												<BlueprintCart name="Nginx Proxy Manager" img={nginxproxymanager} id="NginxProxyManager" version="Latest" />
												<BlueprintCart name="Traefik Proxy" img={traefik} id="Traefik" version="Latest" />
												<BlueprintCart name="Prometheus & Grafana" img={prometheus_grafana} id="PrometheusGrafana" version="Latest" />
												<BlueprintCart name="Jitsi" img={jitsi} id="Jitsi" version="Latest" />
												<BlueprintCart name="GitLab" img={gitlab} id="GitLab" version="Latest" />
												<BlueprintCart name="MariaDB" img={mariadb} id="MariaDB" version="10.6.14" />
												<BlueprintCart name="MongoDB" img={mongodb} id="MongoDB" version="5.0.19" />

											</div>
										</div>

									</TabPanel>

									<TabPanel hidden={selectedTab !== "Security"}>
										<div className=" pb-10">

											<div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-5">
												<div className="pt-3 lg:col-span-2 rounded-md bg-neutral-100 dark:bg-bgPagedark  shadow-lg border dark:border-zinc-800 dark:shadow-darkSiderbar">
													<div className="p-5" >
														<span className="flex text-xl">
															<FaDownload className='w-6 h-6 mr-2' />
															<span className="font-bold">Port Vulnerability Scan</span>
														</span>
														<p className="2xl:text-base text-sm mt-3">
															Scan for potential vulnerabilities on the open ports of the server
														</p>

														<div className="pt-3">
															<OkButton title="Scan" loanding={onGoingScan} click fun={onScan} noRadius />
														</div>
													</div>
												</div>
												<div className="lg:col-span-3  rounded-md shadow-lg border  dark:shadow-darkSiderbar dark:border-zinc-800  bg-neutral-100 dark:bg-bgPagedark">
													<div className=" p-5 pt-3">
														<span className="flex text-xl">
															<FaNetworkWired className='w-6 h-6 mr-2' />
															<span className="font-bold">Open Port </span>
														</span>
														{!onGoingScan ?
															<div className="flex gap-4 pt-2">
																{scanObj && scanObj?.ports.map((port, id) => {
																	return (
																		<div key={id} className="2xl:w-14 h-10 w-10 2xl:text-base text-sm rounded 2xl:h-14 mt-2 font-bold cursor-pointer text-white dark:text-darkTextColor dark:bg-primary  justify-center items-center bg-primary flex">
																			{port}
																		</div>
																	)
																})}
																{scanObj && scanObj?.ports.length === 0 ?
																	<span className="bg-primary dark:text-darkTextColor 2xl:text-base text-sm">No openned port on your server!</span>
																	:
																	null
																}
																{errorScan ? <span className="2xl:text-base text-sm dark:text-darkTextColor">No informations available</span> : null}
															</div>
															:
															<div className="flex justify-center items-center pt-12">
																<div className='flex  mx-auto '>
																	<svg className="animate-spin  h-5 w-5 text-primary dark:text-darkTextColor mx-auto" fill="none" viewBox="0 0 24 24">
																		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
																		<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
																	</svg>
																</div>
															</div>
														}
													</div>
												</div>
											</div>

											<div className="grid grid-cols-1 gap-3 lg:mt-8 mt-3 md:grid-cols-2 lg:grid-cols-5">
												<div className="pt-3 lg:col-span-2">
													<div className="rounded-md bg-neutral-100 dark:bg-bgPagedark p-5 shadow-lg border dark:border-zinc-800 dark:shadow-darkSiderbar" >
														<span className="flex text-xl">
															<AiOutlineGlobal className='w-6 h-6 mr-2' />
															<span className="font-bold">General Informations</span>
														</span>
														{!onGoingScan ?
															<div>
																<div className="pt-3 2xl:text-base text-sm flex gap-5 pb-2 border-b-2 border-b dark:border-zinc-800">
																	{scanObj && scanObj?.hostnames && <span className="font-bold">Hostname</span>}
																	{scanObj &&
																		<div className="flex flex-wrap">
																			{scanObj?.hostnames.map((hostname, id) => <span>{id !== 0 ? " ," : ""} {hostname}</span>)}
																		</div>
																	}


																	{scanObj && scanObj?.hostnames.length === 0 ?
																		<span className=" dark:text-darkTextColor">No hostname found !</span>
																		:
																		null
																	}

																	{errorScan ? <span className=" dark:text-darkTextColor">No information available</span> : null}
																</div>

																{scanObj && scanObj?.tags.map((tag, id) => {
																	return (
																		<div className="inline-block mr-3" key={id}>
																			<div className="rounded py-2 px-5 mt-2 font-bold cursor-pointer dark:text-white dark:text-darkTextColor   justify-center items-center border-3 border-green-700 border">
																				{tag}
																			</div>
																		</div>
																	)
																})}

															</div>
															:
															<div className="flex justify-center items-center pt-12">
																<div className='flex  mx-auto '>
																	<svg className="animate-spin  h-5 w-5 text-primary dark:text-darkTextColor mx-auto" fill="none" viewBox="0 0 24 24">
																		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
																		<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
																	</svg>
																</div>
															</div>
														}
													</div>
												</div>

												<div className="pt-3 lg:col-span-3 2xl:text-base text-sm">
													<div className="lg:col-span-2  p-5 rounded-md shadow-lg border  dark:shadow-darkSiderbar dark:border-zinc-800  bg-neutral-100 dark:bg-bgPagedark">
														<span className="flex text-xl">
															<MdWarning className='w-6 h-6 mr-2' />
															<span className="font-bold">Web Technologies </span>
														</span>

														{!onGoingScan ?
															<div className=" gap-4 pt-2">
																{scanObj && scanObj?.cpes.map((cpe, id) => {
																	return (
																		<div className="inline-block mr-3" key={id}>
																			<div className="rounded py-2 px-5 mt-2 font-bold cursor-pointer dark:text-white dark:text-darkTextColor   justify-center items-center border-3 border-green-700 border">
																				{cpe}
																			</div>
																		</div>
																	)
																})}
																{scanObj && scanObj?.cpes.length === 0 ?
																	<span className="text-primary dark:text-darkTextColor">No Web Technologies found on your server !</span>
																	:
																	null
																}
																{errorScan ? <span className=" dark:text-darkTextColor">No information available</span> : null}
															</div>
															:
															<div className="flex justify-center items-center pt-12">
																<div className='flex  mx-auto '>
																	<svg className="animate-spin  h-5 w-5 text-primary dark:text-darkTextColor mx-auto" fill="none" viewBox="0 0 24 24">
																		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
																		<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
																	</svg>
																</div>
															</div>
														}
													</div>
												</div>

											</div>

											<div className="grid grid-cols-1 gap-3 lg:mt-8 mt-3 ">

												<div className="pt-3 2xl:text-base text-sm">
													<div className="lg:col-span-2  p-5 rounded-md shadow-lg border  dark:shadow-darkSiderbar dark:border-zinc-800  bg-neutral-100 dark:bg-bgPagedark">
														<span className="flex text-xl">
															<MdWarning className='w-6 h-6 mr-2' />
															<span className="font-bold">Vulnerabilities </span>
														</span>
														<span className="text-xs">Note the device may not be impacted by all these issues, the vulnerabilities are implied based on the software and version.</span>




														{!onGoingScan ?
															<div className="flex gap-4 pt-2">
																{scanObj && scanObj?.vulns.map((vuln, id) => {
																	return (
																		<div key={id} className="pt-3 flex gap-10 pb-2 border-b border-b-2 dark:border-zinc-800">
																			<span className="font-bold w-1/4">{vuln.cve}</span>
																			<span>{vuln.desc}</span>
																		</div>
																	)
																})}
																{scanObj && scanObj?.vulns.length === 0 ?
																	<span className="bg-primary dark:text-darkTextColor">No Vulnerability found on your server !</span>
																	:
																	null
																}
																{errorScan ? <span className=" dark:text-darkTextColor">No information available</span> : null}
															</div>
															:
															<div className="flex justify-center items-center pt-12">
																<div className='flex  mx-auto '>
																	<svg className="animate-spin  h-5 w-5 text-primary dark:text-darkTextColor mx-auto" fill="none" viewBox="0 0 24 24">
																		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
																		<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
																	</svg>
																</div>
															</div>
														}
													</div>
												</div>
											</div>


										</div>
									</TabPanel>

									<TabPanel hidden={selectedTab !== "Storage"}>
										<div className="">
											<StorageVps loadVps={loadVps} server={server} />

										</div>
									</TabPanel>
								</div>


							</div>
						</div>
					}

					{!server &&
						<div className='flex justify-center h-48 items-center'>
							<Spiner fontSize={50} />
						</div>
					}
				</div>
			</div>
		</>
	);
}










