import { useEffect, useState } from 'react';
import { BsInfoCircle } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';

import { NetworkServerCard } from "components/networking/NetworkInstance";
import { OkButton } from "components/OkButton"
import { PlatformServerCard } from 'components/Platform/PlatformInstance';
import { SecurityServerCard } from "components/security/SecurityInstance";

import { AddButton } from "../components/AddButton"
import { ListAppWrapper } from '../components/Bloc';
import { InstanceCar } from "../components/ComputeInstace"
import UserMgt from '../lib/user_managment';
import { pages } from "./preview/pages"
import { Preview } from "./preview/PreviewSection"

const max = 5;

function getTaskNameEspected(vps, expect){
    return convertPropsTaskNameBool(vps?.service?.task_name, expect)
}
function convertPropsTaskNameBool(propsVar, expect){
    return propsVar === expect
}

function ArrayWorker(props, object, isSelector){
    const source = props?.source
    let rr = []
	if(isSelector){
		if(
            convertPropsTaskNameBool(source, "vps") ||
            convertPropsTaskNameBool(source, "vpssecmini") ||
            convertPropsTaskNameBool(source, "vpsmini")
        ){
			rr = object?.vps?.list
        }else if(convertPropsTaskNameBool(source, "kps")){
        	rr =  object?.webapp?.list
        }else if(convertPropsTaskNameBool(source, "vullify")){
        	rr =  object?.vullify?.list
        }else if(convertPropsTaskNameBool(source, "kwaf")){
        	rr =  object?.kwaf?.listKwaf
        }else if(convertPropsTaskNameBool(source, "kslb")){
        	rr =  object?.kslb?.list
        } 

	}else{
        const sorted = object.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime())
		const expect = props?.source
		rr = sorted.filter(vps => getTaskNameEspected(vps, expect))
		
	}
	return rr || []
}
export default function ListItemWrapper(props) {
    const dispatch = useDispatch()
    const list = useSelector(app => ArrayWorker(props, app, true))
    const [start, setStart] = useState(0);
    const [page, setPage] = useState(1);
    const [listVps, setVpsList] = useState([])
    const [check, setCheck] = useState({index1: true})
    const {state} = useSelector(app => app.webapp)
    async function loadVps(){
        const r = await UserMgt.get_user_vps() 
        return ArrayWorker(props, r?.user_vps, false) 
    }

    const onForward = () => {
        const newStart = start+max
        setStart(newStart)
        onAnimate(newStart)
        setPage(page+1)
        try{

            const index = page <= 5 ? page+1 : page - 5
            setCheck({['index'+index]: true})
        }catch(e){
            console.log('can check', e)
        }
    }

    const setPage2 = (p) => {
        setPage(p+1)

        const newStart = p * max > list.length ? list.length - max *p : max *p
        setStart(newStart)
        onAnimate(newStart)
        try{
            const index = p <= 5 ? p+1 : p - 5
            setCheck({['index'+index]: true})
        }catch(e){
            console.log('can check', e)
        }

    }

    const onGoBack = () => {
        const newStart = start ==  max ? 0 : start - max ;
        setStart(newStart)
        onAnimate(newStart)
        setPage(() => {
            if(newStart == 0) return 1
            return page-1
        })

        const index = newStart == 0 ? 1 : page > 5? page - 6 : page -1
        setCheck({['index'+index]: true})
    }
    const  onAnimate = async (init) => { 
        setVpsList([])   
        const servers =   list || await loadVps() || []
        const v = []
        const v2 = servers.filter(r =>{
            if(["vps", "vpsmini", "vpssecmini"].includes(props?.source)){
                return r.service?.task_name === props?.source
            }
            return true
        })
        .slice(init, init+max)
        for(let i =0; i < v2.length ; i ++){
            v.push(v2[i]);
            setVpsList(v)
        }
    }
    async function onCancel(){
        window.$("#notify23").modal('hide') 
    }

    useEffect(()=>{
        console.log('START COM')
        onAnimate(start)
        if(state){
            dispatch({type: "@DEPLOYED", state: false})
            window.$("#notify23").modal('show')
        }
    }, [list, props.source])
    return (
        <>
            {((listVps && listVps?.length) || !pages[props.source]) ?
                <ListAppWrapper check={check}>
                    <div className="px-3 -mt-6 2xl:-mt-3 relative">
                        <div className="container mx-auto max-w-full ">
                            <div className="grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-3  px-4 mb-16 gap-4 items-center">
                                     
                                    {listVps.slice(0, 2).map((vps, i)=>{
                                    	return (
                                    		<RenderCard 
                                    			onAnimate={()=>onAnimate(start)} 
                                    			key={vps.Id} 
                                    			server={vps}
                                    			props={props}
                                    		/>
                                    	)
                                    })}

                                    <AddButton 
                                        showPagintion 
                                        onForward={onForward} 
                                        link={"deploy"}   
                                        title={"Deploy new"} 
                                        canGoBaack={start >=max}
                                        onGoBack={onGoBack}
                                        list={list}
                                        max={max}
                                        setPage={setPage2}
                                        page={page}   
                                        canGoForward={list && start+max < list.length}
                                    />
                                    {listVps.slice(2).map((vps, i)=>{
                                        return (
                                            <RenderCard 
                                                onAnimate={()=>onAnimate(start)} 
                                                key={vps.Id} 
                                                server={vps}
                                                props={props}
                                            />
                                        )
                                    })}

                            </div>
                        </div>
                    </div>
                    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={"notify23"} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
                      <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                          
                          

                            <div className="dark:bg-bgPagedark dark:text-darkTextColor modal-body relative py-6 text-center">
                                <span className="text-3xl">
                                    <BsInfoCircle className="text-5xl mx-auto" />
                                </span>

                                <p className="pt-5">
                                     Please wait between 5 to 8 min to see your new KPS available !
                                    <br/>

                                </p>
                                <div className="pt-10 text-center flex justify-center">
                                        
                                    <OkButton title="Ok, Understood" click fun={onCancel}/>
                                    {"  "}
                                     {/*<OkButton title="Ok" fun={onDeleteMethod}  server={server} loanding={loanding}/>*/}
                                    
                                </div>
                            </div>
                        </div>
                      </div>
                    </div> 
                </ListAppWrapper>
                :
                <Preview 
                    page={pages[props.source] || {}}
                />
            }
        </>
    );
}



function RenderCard(props){
    const source = props?.props?.source
	if(convertPropsTaskNameBool(source, "vpsmini")){
		return <InstanceCar onAnimate={()=>props.onAnimate(props.start)} server={props.server}/>
	}
	if(convertPropsTaskNameBool(source, "vps")){
		return <InstanceCar onAnimate={()=>props.onAnimate(props.start)} server={props.server}/>
	}

	if(convertPropsTaskNameBool(source, "kps")){
		return <PlatformServerCard onAnimate={()=>props.onAnimate(props.start)} server={props.server}/>
	}

	if(convertPropsTaskNameBool(source, "vullify")){
		return <SecurityServerCard onAnimate={()=>props.onAnimate(props.start)} server={props.server}/>
	}
	if(convertPropsTaskNameBool(source, "kwaf")){
		return <SecurityServerCard onAnimate={()=>props.onAnimate(props.start)} server={props.server}/>
	}
	if(convertPropsTaskNameBool(source, "kslb")){
		return <NetworkServerCard onAnimate={()=>props.onAnimate(props.start)} server={props.server}/>
	}
}
