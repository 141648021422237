import { Collapse, message } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { AiFillDatabase } from "react-icons/ai";
import { BiLogoKubernetes } from "react-icons/bi";
import { BsFillHandbagFill, BsFillLightbulbFill } from "react-icons/bs";
import { FaForumbee, FaTicketAlt } from "react-icons/fa";
import { FaDev } from "react-icons/fa";
import { FaComputer, FaDatabase, FaDharmachakra } from "react-icons/fa6";
import { GiWantedReward } from "react-icons/gi";
import { GrShieldSecurity } from "react-icons/gr";
import { IoMdGitNetwork } from "react-icons/io";
import { MdHelp } from "react-icons/md";
import { RiBillFill, RiComputerFill, RiMicrosoftFill, RiShieldUserFill, RiUserFill } from "react-icons/ri";
import { SiWikiquote } from "react-icons/si";
import { TiCloudStorage } from "react-icons/ti";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import logo from "../assets/img/keepsec_1.png"
import logo2 from "../assets/img/KS-logo-2.png"
import { Delete } from "../components/Modals";
import { setNotificationHelper } from "../reducer/action/coreActions"
import { ThemeContext } from '../theme/ThemeContext';
import Utils from '../utils';
import AdminNavbar from './AdminNavbar';

export default function Sidebar() {
    const { theme } = React.useContext(ThemeContext);
    const user = useSelector(app => app.user)
    const {deletingService, returnLink} = useSelector(app => app.core)
    const dispatch = useDispatch()
    const [showSidebar, setShowSidebar0] = useState(false);
    const location = useLocation() 
    const currentYear = new Date().getFullYear();

    const [messageApi, contextHolder] = message.useMessage();

    const setShowSidebar2 = (sider) =>{
        setShowSidebar0(typeof sider ==="boolean" ? sider : !showSidebar)
    }

    const [menuTab, setMenuTab] = useState([
      {
        key: '1',
        label: <MenuTitle primary title="Products" icon={()=><BsFillHandbagFill className="" />}/>,
        children: <MenuLine0
                    setShowSidebar={setShowSidebar2} 
                    subItems={
                        [
                            {
                                label: <MenuTitle title="Compute" icon={()=><RiComputerFill className="" />}/>,
                                children: <MenuLine2 
                                        setShowSidebar={setShowSidebar2}
                                        subItems={[
                                            { label: "VPS", link: "/compute/vps", ready: true },
                                            { label: "VPSM", link: "/compute/vpsmini", ready: true },
                                            { label: "VPSsec", link: "/compute/vpssec" },
                                            { label: "VPSsecM", link: "/compute/vpssecmini" },
                                            { label: "KC2", link: "/compute/kc2" }
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle title="Platform" icon={()=><RiMicrosoftFill className="" />}/>,
                                children: <MenuLine2
                                             setShowSidebar={setShowSidebar2}
                                         subItems={[
                                            { label: "KPS", link: "/app-platform/kps", ready: true },
                                            { label: "KS Fonctions", link: "/app-platform/functions" },
                                            { label: "Grunner", link: "/app-platform/grunner" },
                                            { label: "Gpage", link: "/app-platform/gpage" },
                                            { label: "Container Registry", link: "/app-platform/registry" },
                                            { label: "DSOguard", link: "/app-platform/dsoguard" },
                                            { label: "KS-OPS", link: "/app-platform/ksops" }
                                            // { label: "KPSmini", link: "/app-platform/kpsmini/deploy" },
                                            // { label: "KPSsec", link: "/app-platform/kpssec" },
                                            // { label: "KPSdev", link: "/app-platform/kpsdev" }
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle title="Kubernetes" icon={()=><BiLogoKubernetes className="" />}/>,
                                children: <MenuLine2 
                                         setShowSidebar={setShowSidebar2} 
                                        subItems={[
                                            { label: "KCKS", link: "/kubernetes/kskub" },
                                            { label: "KCKSM", link: "/kubernetes/kskubmini" },
                                            { label: "KHKS", link: "/kubernetes/kskubhybr", ready: true },
                                            { label: "KS-OPS", link: "/kubernetes/kskubops" },
                                            { label: "KHKSsec", link: "/kubernetes/khkssec" }
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle title="Database" icon={()=><FaDatabase className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "Database", link: "/database/database" },
                                            { label: "DatabaseM", link: "/database/databasem" }
                                        ]}
                                    />
                            },
                            {
                                label: <MenuTitle title="Infrastructure" icon={()=><AiFillDatabase className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "InfraFKS", link: "/infrastructure/infrafusion" },
                                            { label: "InfraFKSM", link: "/infrastructure/infrafusionmini" },
                                            // { label: "InfraFusionKSBM", link: "/infrastructure/infrafusionbmetal" },
                                            { label: "Bare Metal", link: "/infrastructure/baremetal" },
                                        ]}
                                    />
                            },
                            {
                                label: <MenuTitle title="Machine Leaning" icon={()=><FaDharmachakra className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "Kubeflow", link: "/ia/kubeflow" },
                                            { label: "Cloud GPU", link: "/ia/cloud-gpu" }
                                        ]}
                                    />
                            },
                            {
                                label: <MenuTitle title="Security" icon={()=><GrShieldSecurity className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "Vullify", link: "/security/vullify", ready: true },
                                            { label: "K-WAF", link: "/security/kwaf", ready: true },
                                            { label: "KVault", link: "/security/kvault" },
                                            { label: "KStunnel", link: "/security/kstunnel" },
                                            { label: "APIsec", link: "/security/apisec" },
                                            { label: "DDOSprotect", link: "/security/ddosprotect" }
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle title="DevTools" icon={()=><FaDev  className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "API", link: "/devtools/api", ready: true },
                                            { label: "Terraform Provider", link: "/devtools/terraform" }
                                        ]}
                                    />
                            },
                            {
                                label: <MenuTitle title="Monitoring" icon={()=><FaComputer className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "Manage Logging", link: "/monitoring/loggging", ready: true },
                                            { label: "Ressource Alert", link: "/monitoring/alert" },
                                            { label: "Uptime", link: "/monitoring/uptime" }
                                        ]}
                                    />
                            },

                            {
                                label: <MenuTitle title="Networking" icon={()=><IoMdGitNetwork className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "Interconnect KS", link: "/networking/knterconnectks" },
                                            { label: "KS-LB", link: "/networking/kslb" },
                                            { label: "VPC", link: "/networking/vpc", ready: true },
                                            { label: "DNS", link: "/networking/dns" },
                                            { label: "Reserved IPs", link: "/networking/reserved-ip" },
                                            { label: "PTR records", link: "/networking/ptr-records" }
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle title="Storage" icon={()=><TiCloudStorage className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "Volume Block Storage", link: "/storage/volume", ready: true },
                                            { label: "Spaces Object Storage", link: "/storage/spaces" },
                                            { label: "File Shares", link: "/storage/file-shares" },
                                            { label: "Backup", link: "/storage/backup" },
                                            { label: "Snapshots", link: "/storage/snapshots" },
                                            { label: "Custom images", link: "/storage/custom-image" }
                                        ]}
                                    />,
                                ready: true
                            }
                            
                        ]
                    }
            />
        ,
      },
      {
        key: '2',
        label: <MenuTitle primary title="Account" icon={()=><RiShieldUserFill className="" />}/>,
        children: <MenuLine2  setShowSidebar={setShowSidebar2}
                    subItems={
                        [
                            { label: "Billing", link: "/billing", icon: () => <RiBillFill className="text-base"/>, ready: true },
                            { label: "Reward", link: "/reward", icon: () => <GiWantedReward className="text-base"/>, ready: true },
                            { label: "Profile", link: "/profile", icon: () => <RiUserFill className="text-base"/>, ready: true },
                            
                        ]
                    }
            />
        ,
      },
      {
        key: '3',
        label: <MenuTitle primary title="Support" icon={()=><MdHelp className="" />}/>,
        children: <MenuLine2   setShowSidebar={setShowSidebar2}
                    subItems={
                        [
                            { label: "Wiki", link: "/wiki", icon: () => <SiWikiquote className="text-base"/>, ready: true },
                            { label: "Ticket", link: "/ticket", icon: () => <FaTicketAlt className="text-base"/>, ready: true },
                            { label: "Forum", link: "/forum", icon: () => <FaForumbee className="text-base"/>, ready: true },
                            
                        ]
                    }
            />
        ,
      },
      {
        key: '4',
        label: <MenuTitle primary title="Order" icon={()=><BsFillLightbulbFill className="" />}/>,
        children: <MenuLine0 
                    setShowSidebar={setShowSidebar2}
                    subItems={
                        [
                            {
                                label: <MenuTitle title="Compute" icon={()=><RiComputerFill className="" />}/>,
                                children: <MenuLine2 
                                             setShowSidebar={setShowSidebar2}
                                        subItems={[
                                            { label: "VPS", link: "/compute/vps/deploy", ready: true },
                                            { label: "VPSM", link: "/compute/vpsmini/deploy", ready: true },
                                            { label: "VPSsec", link: "/compute/vpssec/deploy" },
                                            { label: "VPSsecM", link: "/compute/vpssecmini/deploy" },
                                            { label: "KC2", link: "/compute/kc2" }
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle title="Platform" icon={()=><RiMicrosoftFill className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "KPS", link: "/app-platform/kps/deploy", ready: true },
                                            { label: "KPSmini", link: "/app-platform/kpsmini/deploy" },
                                            { label: "KPSsec", link: "/app-platform/kpssec/deploy" },
                                            { label: "KPSdev", link: "/app-platform/kpsdev/deploy" }
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle title="Kubernetes" icon={()=><BiLogoKubernetes className="" />}/>,
                                children: <MenuLine2  setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "KCKS", link: "/kubernetes/kskub/deploy" },
                                            { label: "KCKSM", link: "/kubernetes/kskubmini/deploy" },
                                            { label: "KHKS", link: "/kubernetes/kskubhybr/deploy", ready: true },
                                            { label: "KS-OPS", link: "/kubernetes/kskubops" },
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle  title="Infrastructure" icon={()=><AiFillDatabase className="" />}/>,
                                children: <MenuLine2 setShowSidebar={setShowSidebar2}  subItems={[
                                            { label: "InfraFKS", link: "/infrastructure/infrafusion/deploy" },
                                            { label: "InfraFKSM", link: "/infrastructure/infrafusionmini/deploy" },
                                            { label: "Bare Metal", link: "/infrastructure/baremetal", ready: true },
                                            { label: "KS-OPS", link: "/infrastructure/ksops" },
                                            // { label: "InfraFusionKSBM", link: "/infrastructure/infrafusionbmetal/deploy" },
                                        ]}
                                    />
                            },
                            {
                                label: <MenuTitle  title="Security" icon={()=><GrShieldSecurity className="" />}/>,
                                children: <MenuLine2 setShowSidebar={setShowSidebar2}  subItems={[
                                            { label: "Vullify", link: "/security/vullify/deploy", ready: true },
                                            { label: "K-WAF", link: "/security/kwaf/deploy" }
                                        ]}
                                    />,
                                ready: true
                            },
                            {
                                label: <MenuTitle title="Networking" icon={()=><IoMdGitNetwork  className="" />}/>,
                                children: <MenuLine2   setShowSidebar={setShowSidebar2} subItems={[
                                            { label: "Interconnect KS", link: "/networking/knterconnectks/deploy" },
                                            { label: "KS-LB", link: "/networking/kslb/deploy" },
                                            { label: "VPC", link: "/networking/vpc/deploy" },
                                        ]}
                                    />
                            },
                            {
                                label: <MenuTitle title="Storage" icon={()=><TiCloudStorage className="" />}/>,
                                children: <MenuLine2 setShowSidebar={setShowSidebar2}  subItems={[
                                            { label: "Volume Block Storage", link: "/storage/volume/deploy", ready: true },
                                            { label: "Spaces Object Storage", link: "/storage/spaces" }
                                        ]}
                                    />,
                                ready: true
                            }
                            
                        ]
                    }
            />
        ,
      }
    ])
    

    React.useEffect(() => {
        dispatch(setNotificationHelper(messageApi))
        if(user?.user?.role?.task_name === "partner"){
            const item = {
                key: 'dynamic',
                label: <MenuTitle primary title="Patners" icon={()=><MdHelp className="" />}/>,
                children: <MenuLine2 
                            setShowSidebar={setShowSidebar2}
                            subItems={
                                [
                                    // { label: "Wiki", link: "/wiki", icon: () => <SiWikiquote className="text-base"/>, ready: true },
                                    // { label: "Ticket", link: "/ticket", icon: () => <FaTicketAlt className="text-base"/>, ready: true },
                                    // { label: "Forum", link: "/forum", icon: () => <FaForumbee className="text-base"/>, ready: true },
                                    
                                ]
                            }
                    />
                ,
            }
            menuTab.splice(2, 0, item)
            setMenuTab([...menuTab])
        }
        if(user?.user?.role?.task_name === "student"){
            const item = {
                key: 'dynamic',
                label: <MenuTitle primary title="Students" icon={()=><MdHelp className="" />}/>,
                children: <MenuLine2 
                            setShowSidebar={setShowSidebar2}
                            subItems={
                                [
                                    // { label: "Wiki", link: "/wiki", icon: () => <SiWikiquote className="text-base"/>, ready: true },
                                    // { label: "Ticket", link: "/ticket", icon: () => <FaTicketAlt className="text-base"/>, ready: true },
                                    // { label: "Forum", link: "/forum", icon: () => <FaForumbee className="text-base"/>, ready: true },
                                    
                                ]
                            }
                    />
                ,
            }
            menuTab.splice(2, 0, item)
            setMenuTab([...menuTab])
        }
        if(user?.user?.role?.task_name === "affiliate"){
            const item = {
                key: 'dynamic',
                label: <MenuTitle primary title="Affiliate" icon={()=><MdHelp className="" />}/>,
                children: <MenuLine2 
                            setShowSidebar={setShowSidebar2}
                            subItems={
                                [
                                    // { label: "Wiki", link: "/wiki", icon: () => <SiWikiquote className="text-base"/>, ready: true },
                                    // { label: "Ticket", link: "/ticket", icon: () => <FaTicketAlt className="text-base"/>, ready: true },
                                    // { label: "Forum", link: "/forum", icon: () => <FaForumbee className="text-base"/>, ready: true },
                                    
                                ]
                            }
                    />
                ,
            }
            menuTab.splice(2, 0, item)
            setMenuTab([...menuTab])
        }
        if(user?.user?.role?.task_name === "startup"){
            const item = {
                key: 'dynamic',
                label: <MenuTitle primary title="Startup" icon={()=><MdHelp className="" />}/>,
                children: <MenuLine2 
                            setShowSidebar={setShowSidebar2}
                            subItems={
                                [
                                    // { label: "Wiki", link: "/wiki", icon: () => <SiWikiquote className="text-base"/>, ready: true },
                                    // { label: "Ticket", link: "/ticket", icon: () => <FaTicketAlt className="text-base"/>, ready: true },
                                    // { label: "Forum", link: "/forum", icon: () => <FaForumbee className="text-base"/>, ready: true },
                                    
                                ]
                            }
                    />
                ,
            }
            menuTab.splice(2, 0, item)
            setMenuTab([...menuTab])
        }
    }, [])
    
    return (
        <>
            {contextHolder}
            <AdminNavbar  
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar2}
                location={location}
            />
            <Delete service={deletingService} returnLink={returnLink} />
            <div
                className={`h-screen select-none fixed top-0 md:left-0 ${showSidebar ? 'left-0' : '-2xl:left-72 -left-60'} overflow-y-auto flex-row flex-nowrap shadow-xl bg-neutral-100 w-60 2xl:w-72 z-10 py-4 px-4 2xl:px-6 transition-all duration-300 dark:text-darkTextColor dark:bg-darkSiderbar transition-all`}
                // style={{transform: "scale(0.5)"}}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    
                        <div className='divide-y divide-dashed'>
                            <NavLink
                                to="/"
                                onClick={()=>{
                                        document.getElementById("menu_hamburger1").checked = false;
                                        setShowSidebar2()
                                    }
                                }
                                className="mt-2 text-center w-full inline-block"
                            >
                                {theme !== "dark" ? <img src={logo} alt="" className='w-24 mx-auto'/> : <img src={logo2} alt="" className='w-24 mx-auto'/>}
                            </NavLink>
                        </div>
                    <div className="flex flex-col pb-20 siderbar">
                   
                        {<div className="my-4 border-b-2 dark:border-gray-600  min-w-full" />}


                        <Collapse 
                            accordion 
                            onChange={()=>{
                                
                                // setShowSidebar()
                                // document.getElementById("menu_hamburger1").checked = false;
                            }} 
                            items={menuTab}  
                            bordered={false}
                            ghost={true}     
                            expandIconPosition={"end"}
                        />
                        
                    </div>

                    <div className="  absolute  w-full mx-auto  left-0 bottom-0 text-center h-15">
                        {/*<button 
                            type="button"
                            className="
                                border-2
                                inline-block 
                                px-5
                                py-1.5
                                xl:py-2
                                2xl:py-2.5
                                text-sm
                                2xl:text-base 
                                font-medium 
                                leading-tight 
                                rounded-full 
                                border-primary
                                text-primary
                                bg-[#fff]
                            "
                        >
                            Support Pin: {user?.user?.pinSupport}
                        </button> */}
                        <div className="font-bold mt-2 text-center text-xs text-primary dark:text-darkTextColor">© {currentYear}, KeepSec Technologies, Inc.</div>
                    </div>
                </div>

            </div>
        </>
    );
}

function MenuTitle({title, icon, primary}){
    const primaryClass = "flex gap-2 2xl:gap-4 space-x-1  2xl:space-x-2  items-center text-base 2xl:text-xl text-primary dark:text-darkTextColor font-semibold"
    return(
        <div 
            className={primary ? primaryClass: "flex gap-2 2xl:gap-4  items-center space-x-1 2xl:space-x-2 text-sm 2xl:text-base text-gray-700 dark:text-darkTextColor font-bold"}>
            {icon()}
            {title}
        </div>
    )
}

function SubMenuLine({setShowSidebar, subitem}){
    const naviage = useNavigate()
    return(
        <NavLink
            onClick={()=>{
                setShowSidebar(false)
                document.getElementById("menu_hamburger1").checked = false;
                }
            }
            to={subitem.link} 
            className={ 
            (
                {isActive}) => (
                !isActive ?
                "  text-gray-700  2xl:px-4 2xl:py-2  hover:bg-primary hover:text-white dark:text-darkTextColor"
                : 
                " text-primary  2xl:px-4 2xl:py-3  dark:text-darkTextColor   bg-white dark:bg-primary shadow-md"
            )+
            " flex items-center px-2 py-1.5 gap-2 rounded-lg 2xl:gap-4 text-sm 2xl:text-base menuItem "
        }
        >
            {subitem.icon ? subitem.icon() : ''}
            {subitem.label}
        </NavLink>
    )
}


function MenuLine0({subItems, primary}){
    const { dontDisplayAllItems } = useSelector(app => app.core) 
    const isAdmin = Utils.isAdmin()
    const [items, setItems] = useState([])
    useEffect(()=>{
        const sCopy = Array.from(subItems)
        setItems(
            sCopy.filter(item => {
                if(!isAdmin || dontDisplayAllItems){
                    return item.ready 
                }
                return true
            }).map(item => {
                return {...item, ready: null}
            })
        )
    }, [isAdmin, dontDisplayAllItems])
    
    return(
        <div className={primary ? "block" : ""}>
                <div  className="text-sm border-l-2 border-gray-800 ml-[12px] 2xl:ml-[14px] mr-0  my-0 px-1 2xl:px-2.5 flex flex-col gap-y-1">
                    <Collapse 
                        accordion
                        onChange={()=>{
                            
                        }} 
                        items={items} 
                        bordered={false}
                        ghost={true}
                        expandIconPosition={"end"}
                    />
                </div>
            
        </div>
    )
}

function MenuLine2({subItems, setShowSidebar}){ 
    const { dontDisplayAllItems } = useSelector(app => app.core)  
    const isAdmin = Utils.isAdmin()
    return(
        <div>
            <div  
                className="
                    text-sm border-l-2 
                    border-gray-800 ml-[12px] 
                    mr-0 2xl:ml-[13px] my-1 px-1 2xl:px-2.5 
                    flex flex-col gap-y-1
                "
            >
                {
                   subItems.filter(item => {
                        if(!isAdmin || dontDisplayAllItems){
                            return item.ready 
                        }
                        return true 
                    }).map((subitem, i) =>{
                        return(
                            <SubMenuLine  
                                subitem={subitem}
                                key={i}
                                setShowSidebar={setShowSidebar}
                            />
                        )
                   }) 
                }
            </div>
            
        </div>
    )
}
