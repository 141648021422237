import { MinusSquareFilled } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import { Spin } from 'antd';
import { Input } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { BiHelpCircle } from "react-icons/bi";
import { MdAddCircle } from "react-icons/md";

import { ExtLink } from 'components/Bloc';
import { PageTitle } from "components/Bloc";
import { CardLayout } from 'layouts/CardLayout';
import UserMgt from "lib/user_managment";

import { AddLineButton, NetworkRender, RemoveLineButton } from '../../../components/Bloc';
import { Firewall } from '../../../components/Firewall'
import { OkButton } from "../../../components/OkButton"

const { Search } = Input;


export function NetworkingVps({ server, loadVps }) {
	const [messageApi, contextHolder] = message.useMessage()
	const [listVps, setListVps] = useState([])
	const [objectPortCreate, setObjectPortCreate] = useState({})
	const [objectPortDelete, setObjectPortDelete] = useState({})
	const [loadingNetworkCreation, setloadingNetwork] = useState(false)
	const [onDisplayrull, setOnDisplayRuleComponent] = useState(false)
	const [search1, setSearch1] = useState("")
	const [search2, setSearch2] = useState("")

	

	const getListVps = async () => {
		const r = await UserMgt.get_user_vps() 
		setListVps(r?.user_vps.filter(vps => vps?.service?.task_name==="vps" && vps?.VpsStatus ==="up" && vps?.location?.id === server?.location?.id))
	}
	useEffect(() => {
		getListVps()
	}, [])

	const toogleInterConnect = async () => {
		await onEnableNetwork()
	}
	const onCreatePort = async (vps) => {
		setObjectPortCreate({...objectPortCreate, [vps.Id]: true})
		try {
			const result  = await UserMgt.onPortCreate({server_id: vps?.Id, isDefault: true})
			console.log('Result Port ', result)
			if(result && !result.error){
				messageApi.open({
					type: 'success',
					content: 'Done !'
				});
				await getListVps()
				await loadVps()
			}else{
				messageApi.open({
					type: 'error',
					content: 'An error occurs, please try again or contact an admin!'
				});
			}
		} catch (error) {
			console.error('Error createPort', error)
		}
		setObjectPortCreate({...objectPortCreate, [vps.Id]: null})
	}
	const onDeletePort = async (vps) => {
		setObjectPortDelete({...objectPortCreate, [vps.Id]: true})
		const defaultVpc = vps?.vpc?.find(vpc => vpc?.isDefault)
		console.log('defaultVpc defaultVpc defaultVpc ', defaultVpc)
		try {
			const data = {
				portId: defaultVpc?.openstackPortId,
				server_id: vps?.Id, 
				isDefault: true
			}
			const result  = await UserMgt.onPortCreate(data, true)
			console.log('Result Port ', result)
			if(result && !result.error){
				messageApi.open({
					type: 'success',
					content: 'Done !'
				});
				await getListVps()
				await loadVps()
			}else{
				messageApi.open({
					type: 'error',
					content: 'An error occurs, please try again or contact an admin!'
				});
			}
		} catch (error) {
			console.error('Error createPort', error)
		}
		setObjectPortDelete({...objectPortCreate, [vps.Id]: null})
	}



	const onEnableNetwork = async () => {
		try {
			setloadingNetwork(true)
			const result = await UserMgt.onEnableNetwork(server?.Id);
			console.log('Enable network result ', result)
			if(!result.error){
				messageApi.open({
					type: 'success',
					content: 'VPC as been enabled !',
				});
				await loadVps()
			}else{
				messageApi.open({
					type: 'error',
					content: result.message || "An errors occurs, please try again or contact us.",
				});
			}
		} catch (error) {
			console.log('Error network', error)
			messageApi.open({
				type: 'error',
				content: "An errors occurs, please try again or contact us.",
			});
		}
		setloadingNetwork(false)
	}

	return (
		<div className="pb-10">
			{contextHolder}
			<div className="lg:flex  pb-2 ">
				<div className="w-full lg:w-[55%] md:mr-2 ">
					<div className="md:h-[17%]">
						<div className="flex gap-2 items-center">
							<PageTitle text="IPv4 Networking" />
							
						</div>
						<p className="xl:text-sm 2xl:text-base pt-2">
							The IP address of your instance is accessible to the internet,
							the private IP address is accessible only to other ressources in your KeepSec  account.

						</p>
					</div>
					<div className="md:flex md:justify-between pt-3">

						<div className="md:w-[49%] ">
							<div className="w-full mt-4">
								<span className="xl:text-sm 2xl:text-base text-slate-500 font-semibold">
									PUBLIC IP
								</span>
								<CardLayout className=" px-4 py-4 mt-2 h-[100px]">
									
									<span className="xl:text-xl 2xl:text-2xl font-bold">
										{server.OpenstackInstanceExtIp}
									</span>

									<br />
								</CardLayout>
							</div> 


							
							<div className="w-full pt-5">
								<div className="flex gap-2 items-center ">
									<p className="text-sm xl:text-xl 2xl:text-2xl">IPv4 Firewall</p>
									<BiHelpCircle className="text-blue-700 text-sm xl:text-xl 2xl:text-2xl" />
								</div>
								<p className="text-sm 2xl:text-base pt-2">
									Create rules to open ports to the internet or to specify IPv4 addresses and ranges.
									<br />

									<ExtLink 
										href={"#"}
										text="Lean more about firewall rules"
									/>
								</p> 
							</div>
						</div>

						<div className="md:w-[49%] ">
							<div className="w-full mt-4 ">
								<span className="xl:text-sm 2xl:text-base text-slate-500 font-semibold">
									VPC IP
								</span>
								<div className="border-2 h-[100px] flex items-center dark:bg-bgPagedark dark:border-black border-gray-300  rounded-md px-4 mt-2 shadow-lg">
									

									{server?.vpc?.length ?

										<div className="">
											<p className="text-sm 2xl:text-base  font-semibold">
												<NetworkRender
													vpc={server?.vpc}
												/>
											</p>
											<ExtLink 
												href={"#"}
												text="What is this for ?"
												className='mt-3'
											/>

										</div>
										:
										<p className="text-sm 2xl:text-base">VPC needs to be enabled and have the server in the availability zone</p>
									
									}	
								</div>
							</div>

							<div className="w-full pt-5">
								<span className="text-sm 2xl:text-base text-slate-500 font-semibold">
									VPC SUBNET
								</span>
								<CardLayout className="flex h-[100px] justify-center items-center px-4 mt-2 ">
									<div>
										{server?.vpc?.length  ?
											<span className="xl:text-xl 2xl:text-2xl font-bold">
												{server?.vpc?.map((vpc, i) => {
													return <span key={i}>{(i !==0 ? ", " : "")+vpc.network?.subnet}</span>
												})}
											</span>
											:
											<p className="text-sm 2xl:text-base">VPC needs to be enabled</p>
										}
											{/*<span className="xl:text-xl 2xl:text-2xl font-bold">{server?.network?.subnetObj?.cidr}</span>*/}
									</div>
								</CardLayout>
							</div>
						</div>

					</div>
				</div>


				<div className="w-full lg:w-[45%] pt-5 md:pt-0 ">
					<div className="md:h-[17%]">
						<div className="flex gap-2 items-center">
							<p className="text-base 2xl:text-2xl xl:text-xl">IPv4 Dedicated Network</p>
						</div>
						<p className="text-sm 2xl:text-base pt-2">
							This network will only be accessible to other VPS's added in your VPC availability zone.
						</p>
					</div>
					<div className="mt-4  pt-3 " >
						<span className="text-sm 2xl:text-base text-slate-500 font-semibold">
							VPC
						</span>
						<CardLayout className="px-2 mt-2">
							{!loadingNetworkCreation ?

								<div className="w-full">
									{!server?.vpcActif &&
										<div className="flex py-[50px] xl:py-[70px]  justify-center items-center ">

											<div>
												<OkButton title="Enable" width={36} loanding={false} click fun={toogleInterConnect} noRadius />
												<br />
												<OkButton title="Lean more" width={36} noRadius outlined />
											</div>

										</div>
									}
									{server?.vpcActif &&
										<div className="text-sm 2xl:text-base pt-5">
											<div className="flex items-end justify-between gap-2
											">
												<strong>Add and remove VPS from your VPC network</strong>
												<div className="flex text-xs 2xl:text-sm items-center justify-end  gap-1 text-primary dark:text-darkTextColor">
													<span className="font-bold text-primary dark:text-darkTextColor">Zone</span>
													<span className="bg-primary font-semibold rounded-xl text-[10px] 2xl:text-sm px-2 text-white">
														{(server?.location?.name || '').slice(9)}
													</span>
												</div>
											</div>
											<div className="md:flex py-3">
												<div className="w-full md:w-1/2 md:pr-2 pt-3 	">
													<div className=" border ">
														<div className=" pt-3 pb-3   flex items-center justify-between">
															<span className="pl-2 text-xs 2xl:text-sm font-semibold">My VPS</span>
															<div className="flex w-2/3 ">
																<Search
																	placeholder="input search "
																	onSearch={(value, _e, info)=> setSearch1(value) }
																	onChange={(ev)=> setSearch1(ev.target.value)}
																	allowClear
																/>
															</div>
														</div>
														<div className="min-h-[110px] max-h-[150px] md:min-h-[130px] md:max-h-[170px] overflow-y-auto" >

															{listVps && listVps.filter(vps => vps.DisplayName.toLowerCase().includes(search1.toLowerCase())).map((vps, i) =>{
																if(!vps.vpc?.find(vpc => vpc?.isDefault)){
																	return(
																		<div key={i} className={"border-b text-sm 2xl:text-base py-1  flex items-center justify-between "+(i%2 == 0 ? "bg-neutral-100 dark:bg-bgPagedark" : "")}>
																			<span className="pl-2 text-sm Zxl:text-base">
																				{vps.DisplayName}
																			</span>

																			<span className=" mr-1 cursor-pointer">
																				{!objectPortCreate[vps.Id]?
																					<AddLineButton
																						placeholder={"Add " + vps.DisplayName +" to current zone"}
																						onFunction={() => onCreatePort(vps)}
																					/>
																					:
																					<Spin />
																				}
																			</span>
																		</div>
																	)
																}
															})}

															
														</div>
													</div>
												</div>


												<div className="w-full md:w-1/2 md:pl-2 pt-3	">
													<div className="border ">
														<div className=" pt-3 pb-3  text-xs 2xl:text-sm  flex items-center justify-between">
															<span className="pl-2 text-xs 2xl:text-sm font-semibold">Availability Zone</span>
															<div className="flex w-2/3  rounded">
																<Search
																	placeholder="input search"
																	onSearch={(value, _e, info)=> setSearch2(value) }
																	onChange={(ev)=> setSearch2(ev.target.value)}
																	allowClear
																	
																/>
															</div>
														</div>
														<div className="min-h-[110px] max-h-[150px] md:min-h-[130px] md:max-h-[170px] overflow-y-auto" >
															{listVps && listVps.filter(vps => vps.DisplayName.toLowerCase().includes(search2.toLowerCase())).map((vps, i) =>{
																if(vps.vpc?.find(vpc => vpc?.isDefault)){
																	return(
																		<div key={i} className={"border-b text-sm 2xl:text-base py-1  flex items-center justify-between "+(i%2 == 0 ? "bg-neutral-100 dark:bg-bgPagedark" : "")}>
																			<span className="pl-2 text-sm Zxl:text-base">
																				{vps.DisplayName}
																			</span>

																			<span className=" mr-1 cursor-pointer">
																				{!objectPortDelete[vps.Id]?
																					<RemoveLineButton
																						placeholder={"Remove " + vps.DisplayName +" to current zone"} 
																						onFunction={() => onDeletePort(vps)}
																					/>	
																					:
																					<Spin />
																				}
																			</span>
																		</div>
																	)
																}
															})}

															
														</div>
													</div>
												</div>
											</div>
										</div>
									}
								</div>
								:
								<div className="flex h-full  justify-center items-center ">
									<Spin />
								</div>
							}
						</CardLayout>
							
					</div>
				</div>
			</div>

			<div className="mt-5 pl-5 pt-3">
				<span className="text-base font-bold w-28 flex cursor-pointer" onClick={()=>{setOnDisplayRuleComponent(!onDisplayrull)}}>
					<MdAddCircle className="text-primary text-2xl" />
					Add Rule
				</span>
			</div>
			<Firewall
				onDisplayrull={onDisplayrull}
				server={server}
				setOnDisplayRuleComponent={setOnDisplayRuleComponent}
				reload={loadVps}
			/>
		</div>
	)
}



