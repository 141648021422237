import { DeleteFilled, InfoCircleFilled } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { FaBuilding } from "react-icons/fa";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import AddBotom from 'components/vps/AddBotom';
import { VpsPack } from 'components/vps/Packages';
import PromoBottom from 'components/vps/PromoBottom';
import { ServerLocation } from 'components/vps/ServerLocation';
import TrialBottom from 'components/vps/TrialBottom';

import controlPlane from '../../assets/img/k8/controlplane.png';
import versionImage from '../../assets/img/k8/kubernetes-kp.png';
import nodeSelector from '../../assets/img/k8/node-kp.png';
import { AddServiceSectionTitle, DeployAdAdmin, InputTextAdd, LabelAdd, Spiner } from '../../components/Bloc';
import { Toast } from '../../components/Notify';
import Paypal from '../../components/PaypalModal';
import UserAccountConfirmPrompt from '../../components/UserAccountConfirmPrompt';
import K8S from '../../lib/k8s';
import UserMgt from '../../lib/user_managment';
import Utils from '../../utils';

const ks8version = [
    "1.27.14",
    "1.28.10",
    "1.29.5",
    "1.30.2"
]

const haPrice = 40;
const zeroPad = Utils.zeroPad // i => ("" + (i)).padStart(2, '0')

export default function KskubhybrNew(props) {
    const { isTrial, isPromo } = props
    const ha1 = [
        {
            id: Utils.generateId(),
            nodes: [
                { id: Utils.generateId(), isMaster: true },
                { id: Utils.generateId() }
            ]
        },
        {
            id: Utils.generateId(),
            nodes: [
                { id: Utils.generateId(), isMaster: true },
                { id: Utils.generateId() }
            ]
        }
    ]
    const [hoverServerName, setHoverToolTopServerName] = useState(false)
    const user = useSelector(app => app?.user?.user)



    const { vps_packages } = useSelector(app => app.vps)

    const [listNode, setListNode] = useState(
        [
            { id: Utils.generateId(), isMaster: true, isPrimarykubeMaster: true },
            { id: Utils.generateId() }
        ]
    )
    const [heightBottomC, setBottomCalculedHeight] = useState(0)

    const [allClusterHaList, setHAlistCLuster] = useState(ha1)

    const [selectedVersion, setSelectedVersion] = useState(null)
    const [clickedVersion, setClickedVersion] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)
    const [link, setlink] = useState(null)
    const [kubePack, setKubePack] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        image: "KSKubhybr",
        version: "",
        trialCode: null,
        promoCode: null,
        isWithPromo: isPromo,
        isWithTrial: isTrial,
        isAdminFreeDeployment: null
    })


    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)


    const canAddControlPlane = (i) => {
        const filtered = listNode?.filter(n => n.isMaster)
        return i === filtered.length - 1  && filtered.length !== 7
    }

    const deletable = (node) => {
        if (node?.isMaster) {
            const filtered = listNode?.filter(n => n.isMaster)
            if (filtered.length === 1) return false
            return filtered[filtered.length - 1]?.id === node.id
        }
        else {
            const filtered = listNode?.filter(n => !n.isMaster)
            if (filtered.length === 1) return false
            return filtered[filtered.length - 1]?.id === node.id
        }
    }


    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    function addHACluster() {
        const nodes = [
            { id: Utils.generateId(), isMaster: true },
            { id: Utils.generateId() }
        ]
        const h = allClusterHaList
        h.push({ id: Utils.generateId(), nodes })
        setHAlistCLuster([...h])
    }
    function deleteHACluster(id) {
        console.log('ID ', id)
        console.log('allClusterHaList ', allClusterHaList)
        const h = allClusterHaList
        const newNode = h.filter(cluster => cluster.id !== id)
        setHAlistCLuster([...newNode])
    }

    async function loadImages() {
        UserMgt.getVpsImages() //VPSapi.image_list()
    }
    async function getVpsTypes() {
        if (!vps_packages) {
            const r = await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        }
        return vps_packages
    }




    useEffect(() => {
        if (Utils.getUrlParam("code")) {
            setVpsParams({ ...vps_params, trialCode: Utils.getUrlParam("code"), promoCode: Utils.getUrlParam("code") })
            onApplyCode(Utils.getUrlParam("code"))
        }
        getVpsTypes()
    }, [])



    async function onApplyCode(code) {
        if (!isPromo) return
        try {
            let c = code || vps_params.promoCode
            if (c && c.trim() !== "") {
                const result = await UserMgt.check_promo_code(c);
                if (result && result.code) {
                    return setCodeApplied(result?.code)
                } else {
                    return Toast.fire({
                        icon: 'error',
                        title: "Invalid promotion code"
                    })
                }
            } else {
                return Toast.fire({
                    icon: 'error',
                    title: "Please enter promotion code first !"
                })
            }

        } catch (e) {
            console.log('error applying code', e)
        }

        return Toast.fire({
            icon: 'error',
            title: "An error occurred, try again or contact us !"
        })
    }

    const totalPrice = () => {
        let allPrice = listNode &&
            listNode.map(node => parseFloat(node?.pack?.Price || 0)).reduce((a, b) => a + b, 0)
            + (vps_params?.isHA ?
                haPrice : 0
            )

        if (vps_params?.isHA) {
            for (let i = 0; i < allClusterHaList?.length; i++) {
                let localNodes = allClusterHaList?.[i]?.nodes
                allPrice += localNodes.map(node => parseFloat(node?.pack?.Price || 0)).reduce((a, b) => a + b, 0)
            }
        }
        return allPrice || null
    }
    async function deployVps(paymentId, dataPayment) {
        const data = {
            paymentId,
            dataPayment,
            ...getDeployParams()
        }
        const result = await UserMgt.create_k8s(data, dataPayment)
        setCreating(false)
        if (!result || result.error || result.status !== "success") {
            Toast.fire({
                icon: 'error',
                title: result.message || "An error occurred while running. If this persist, please contact us"
            })
        } else {
            Toast.fire({
                icon: 'success',
                title: result.message || "K8S cluster created"
            })
            const links = result?.result?.links
            console.log('links links links', links);
            const link = links && links.length && links.find(l => l.rel === "approve")
            if (link) {
                setlink(link?.href)
                window.open(link?.href, "Confirm", "width=500,height=500, fullscreen=yes")
            }
            if (result && !result.needToConfirm) {
                // dispatch({ type: "@DEPLOYED_VPS", state: true })
                await K8S.getK8sInstance()
                navigate('/kubernetes/kskubhybr')
            }
        }

    }

    function getDeployParams() {
        const params = {
            ...vps_params,
            nodes: listNode,
            clusterHA: allClusterHaList //.filter(node =>  node?.pack)
        }
        delete params.image
        return params
    }


    async function onPay(ev) {
        ev.preventDefault()
        const data = getDeployParams()
        console.log('START DEPLOY HERE', data)
        let { nodes } = data

        let masterControlPlane = nodes.filter(node => node.isMaster)
        if (!(masterControlPlane.length % 2)) {
            return Toast.fire({
                icon: 'error',
                title: "The total of  control planes should be an  odd number."
            })
        }
        if (data.isHA) {
            let HAClusterNode = []
            for (let i = data?.clusterHA?.length - 1; i >= 0; i--) {
                const localNodes = data?.clusterHA?.[i]?.nodes
                HAClusterNode = HAClusterNode.concat(localNodes)
                nodes = nodes.concat(localNodes)
            }
            const HAClusterMasterNode = HAClusterNode.filter(node => node.isMaster)
            masterControlPlane = masterControlPlane.concat(HAClusterMasterNode)
            //num % 2
            if (HAClusterMasterNode.length < 2) {
                return Toast.fire({
                    icon: 'error',
                    title: "The HA clusters should have at least 2 control planes."
                })
            }
            if ((HAClusterMasterNode.length % 2)) {
                return Toast.fire({
                    icon: 'error',
                    title: "The total of HA clusters control planes should not be an  odd number."
                })
            }
        }
        if (!(masterControlPlane.length % 2)) {
            return Toast.fire({
                icon: 'error',
                title: "The total of all control planes including HA clusters should be an  odd number."
            })
        }
        const nameNodes = nodes.map(node => node.name)
        const duplicatedName = nameNodes && nameNodes.filter((e, i, a) => a.indexOf(e) !== i)

        if (duplicatedName && duplicatedName.length > 0) {
            let string = "";
            for (let i = 0; i < duplicatedName.length; i++) {
                string = string + (string === "" ? "" : ", ") + duplicatedName[i]
            }
            return Toast.fire({
                icon: 'error',
                title: "The name '" + string + "' is duplicated."
            })
        }

        for (let i = 0; i < data.nodes.length; i++) {
            console.log('nodes nodes ', nodes)
            if (!data.nodes[i].pack) {
                if (!nodes[i].isMaster) {
                    return Toast.fire({
                        icon: 'error',
                        title: "Node-" + zeroPad(i) + " plan of primary cluster didn't picked"
                    })
                }
                if (data.nodes[i].isMaster) {
                    return Toast.fire({
                        icon: 'error',
                        title: "Master node plan of primary cluster didn't picked"
                    })
                }
            }
        }
        if (data.isHA) {
            if (!data?.clusterHA?.length) {
                return Toast.fire({
                    icon: 'error',
                    title: "You should add at least 2 control planes for HA clusters"
                })
            }

            for (let i = 0; i < data?.clusterHA?.length; i++) {
                if (!data?.clusterHA?.[i].location) {
                    return Toast.fire({
                        icon: 'error',
                        title: "Location of HA cluster-" + zeroPad(i + 1) + " didn't selected."
                    })
                }
                let localNodes = data?.clusterHA?.[i]?.nodes
                for (let y = 0; y < localNodes.length; y++) {
                    if (!localNodes[y].pack) {
                        if (!localNodes[y].isMaster) {
                            return Toast.fire({
                                icon: 'error',
                                title: "Node-" + (y) + " plan of HA cluster-" + zeroPad(i + 1) + " didn't picked."
                            })
                        }
                        if (localNodes[y].isMaster) {
                            return Toast.fire({
                                icon: 'error',
                                title: "Master node plan of HA cluster-" + zeroPad(i + 1) + " didn't picked"
                            })
                        }
                    }
                }
            }
        }

        if (!vps_params.version) {
            return Toast.fire({
                icon: 'error',
                title: "kubernetes version not selected"
            })
        }

        if (isPromo && !codeApplied) {
            return Toast.fire({
                icon: 'error',
                title: "You should apply promo code !"
            })
        }
        if (!vps_params.location) {
            return Toast.fire({
                icon: 'error',
                title: "Service location not selected"
            })
        }
        setCreating(true)
        if (!vps_params.isAdminFreeDeployment && !Utils.isDemo()) {
            const result = await K8S.createPlan(getDeployParams(), totalPrice());
            setKubePack({ PaypalPackId: result?.id, PaypalPackIdFree: result?.id })
            if (!isPromo) {
                if (vps_params.trialCode && isTrial) {
                    const r = await UserMgt.check_trial_code(vps_params.trialCode)
                    const isValid = r && r.code && (!!r.code.IsMulti || !(!!r.code.IsUsed));
                    if (!isValid) {
                        setCreating(false)
                        setPaypal(false)
                        return Toast.fire({
                            icon: 'error',
                            title: "Trial code is not valid !"
                        })
                    }
                }
                setPaypal(true)
            } else {
                return deployVps()
            }
        } else {
            return deployVps()
        }
    }


    function onDismiss() {
        setCreating(false)
        setPaypal(false)
    }
    // function onAddNodeItem() {
    //     const node = listNode
    //     node.push({id: Utils.generateId()})
    //     setListNode([...node])
    // }
    function onAddNodeItem(controlPlane) {
        const nodes = listNode
        if (controlPlane) {
            const filtered = listNode?.filter(n => n.isMaster)
            const sameId = Utils.generateId()
            nodes.splice(
                filtered.length,
                0,
                {
                    id: Utils.generateId(),
                    isMaster: true,
                    groupId: sameId
                }
            )
            nodes.splice(
                filtered.length,
                0,
                {
                    id: Utils.generateId(),
                    isMaster: true,
                    groupId: sameId
                }
            )
        } else {
            nodes.push(
                { id: Utils.generateId() }
            )
        }
        setListNode([...nodes])

    }
    function deleteNode(nodeId) {
        const node = listNode.find(node => node.id === nodeId)
        let nodes;
        if (node.isMaster) {
            nodes = listNode.filter(localNode => localNode.groupId !== node.groupId)
        } else {
            nodes = listNode.filter(node => node.id !== nodeId)
        }
        setListNode([...nodes])
    }

    function createPaymentAndDeploy(paymentID, dataPayment) {
        setPaypal(false)
        console.log('data payment start, createPaymentAndDeploy', paymentID, dataPayment)
        const dataPayment2 = {
            ...dataPayment,
            withFirstTrial: isTrial,
            amountPaid: totalPrice() //parseFloat(packSelected?.Price),
        }
        return deployVps(paymentID, dataPayment2)
    }

    const concertPack = vps_packages &&
        vps_packages.filter(p => p.service?.task_name === "vps")
            .sort((a, b) => parseInt(a.Price) - parseInt(b.Price))
    return (
        <div className='relative'>

            <div className={user && !user?.EmailConfirmed ? 'opacity-[20%] h-full' : 'h-full'}>
                <>
                    <Paypal
                        createPaymentAndDeploy={createPaymentAndDeploy}
                        vpsType={kubePack}
                        onDismiss={onDismiss}
                        withFirstTrial={isTrial}
                        withPromo={isPromo}
                        open={paypal}
                        label="kubernetes subscription"
                        finalPrice={totalPrice}
                    />

                    <div className="px-3 md:px-8  relative dark:text-darkTextColor ">
                        <div className="container max-w-full">
                            <div className='mx-auto'>
                                <ServerLocation label="Choose your cluster location" vps_params={vps_params} setVpsParams={setVpsParams} />
                                {concertPack ?
                                    <form onSubmit={onPay} className="-mt-2">
                                        <AddServiceSectionTitle
                                            title="Pick your cluster version"
                                        />

                                        <div className=" 2xl:gap-10 md:gap-6 gap-3 mt-5 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap  justify-center ">
                                            {ks8version && ks8version.map((version, k) =>
                                                <div onClick={() => {
                                                    if (!creating) {
                                                        if (clickedVersion == version) {
                                                            setClickedVersion(null);
                                                            setVpsParams({ ...vps_params, version: null })
                                                        } else {
                                                            setVpsParams({ ...vps_params, version: version })
                                                            setClickedVersion(version);

                                                        }
                                                    }
                                                }}
                                                    onMouseOver={() => setSelectedVersion(version)}
                                                    key={k}
                                                    className={'w-48 relative dark:bg-bgPagedark  mt-5  cursor-pointer rounded-xl ' +
                                                        ((selectedVersion == version) ? "hover:bg-white  hover:shadow-2xl " : '') + "" + ((clickedVersion == version) ? "bg-white shadow-xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')}>

                                                    {(clickedVersion == version) &&
                                                        <svg className="div_check checkmark absolute right-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                                            <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    }
                                                    <div className=' relative items-center gap-3 flex pl-4 pr-8 py-[4px]'>
                                                        <img src={versionImage} alt="" className='w-[30px] md:w-[35px] xl:w-[40px] 2xl:w-[45px]' />
                                                        <span className="font-bold text-sm xl:text-base">v{version}</span>
                                                        {/*<div className="ml-[75px] flex py-3 pr-3 items-center justify-between">
                                                                <p className='text-primary dark:text-darkTextColor font-semibold text-xl'>{"Debian KS12"}</p>
                                                                &nbsp;&nbsp;
                                                                <img src={levels['level' + (k + 1)]} alt="" className='w-14' />
                                                            </div>*/}
                                                    </div>

                                                </div>
                                            )}
                                        </div>

                                        {listNode.map((node, i) => {
                                            return (
                                                <div className={``}
                                                    key={i}
                                                >
                                                    <NodeSelector
                                                        key={i}
                                                        i={i}
                                                        concertPack={concertPack}
                                                        creating={creating}
                                                        // onClaculFlavor={onClaculFlavor}
                                                        node={node}
                                                        withAdmin
                                                        isTrial={isTrial}
                                                        user={user}
                                                        setVpsParams={setVpsParams}
                                                        listNode={listNode}
                                                        setListNode={setListNode}
                                                        onAddNodeItem={onAddNodeItem}
                                                        deleteNode={deleteNode}
                                                        vps_params={vps_params}
                                                        first={i === 0}
                                                        canDeleted={deletable(node)}
                                                        last={i + 1 === listNode.length}
                                                        title={node?.isMaster ?
                                                            "Choose your control plane " + ("" + (i + 1)).padStart(2, '0') + " plan "
                                                            :
                                                            "Choose your node-" + ("" + (
                                                                i + 1 - listNode?.filter(n => n.isMaster).length
                                                            )).padStart(2, '0') + " plan"
                                                        }

                                                        canAddControlPlane={() => canAddControlPlane(i)}
                                                    />
                                                </div>
                                            )
                                        })}

                                        <AddServiceSectionTitle
                                            title={"Cluster Informations"}
                                        />

                                        <div className={'border-b-2  dark:border-gray-800  px-20 mx-auto pt-10  '}>
                                            <div className=" flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full xl:w-[40%] px-3 mb-6 md:mb-0">
                                                    <LabelAdd
                                                        htmlFor="serv-name"
                                                        placeholder={"Identify your cluster"}
                                                    />
                                                    <Tooltip open={hoverServerName} title="Must not contain spaces, special characters or symbols">
                                                        <InputTextAdd
                                                            idx="serv-name"
                                                            value={vps_params.server_name}
                                                            name="server_name"
                                                            pattern="[A-Za-z0-9]{1,}"
                                                            onChange={handleChange}
                                                            required
                                                            hover
                                                            onHover={(state) => setHoverToolTopServerName(state)}
                                                            disabled={creating}
                                                            placeholder='Your instance resources must have unique names'
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>


                                        </div>
                                        <div style={{ paddingBottom: heightBottomC + 115 }} >
                                            {Utils.isAdmin() ?
                                                <div>
                                                    <AddServiceSectionTitle
                                                        title={"Select additionnal options"}
                                                    />
                                                    <div
                                                        className={`
                                                                border-b-2  
                                                                dark:border-gray-800  
                                                                xl:px-20 mx-auto pt-5  `
                                                        }
                                                    >
                                                        <div className="pb-4 my-5 border-2 rounded-md border-gray-300 w-full   shadow-lg">
                                                            <div className="text-base bg-neutral-100 p-6 2xl:text-xl flex gap-2">
                                                                <InfoCircleFilled className="text-blue-500 text-2xl" />
                                                                <span className="font-semibold">
                                                                    We recommend high availability to eliminate a single point of failure and increase peace of mind for production workloads.
                                                                </span>

                                                            </div>
                                                            <div className="p-4 text-sm 2xl:text-base border-t-2 border-gray-300">
                                                                <span className="font-bold text-base 2xl:text-xl">
                                                                    Get extra reliability for critical workloads
                                                                </span>
                                                                <p className="mt-3 font-semibold ">
                                                                    A&nbsp;<span className="text-blue-500 inline-flex items-center">
                                                                        high availability control plane&nbsp; <FaBuilding />
                                                                    </span>&nbsp;creates multiple replicas of
                                                                    control plane components to maximize cluster access and uptime with a 99.95% SLA.
                                                                </p>
                                                            </div>
                                                            <div className="pt-4 px-4 flex justify-between border-t-2 border-gray-300">
                                                                <div>

                                                                    <Checkbox
                                                                        className="text-sm md:text-base 2xl:text-xl font-bold"
                                                                        size='large'
                                                                        disabled={creating}
                                                                        onChange={(e) => {
                                                                            setVpsParams({ ...vps_params, isHA: e.target.checked })
                                                                            setHAlistCLuster(ha1)
                                                                        }}
                                                                    >
                                                                        Add high availability
                                                                    </Checkbox>
                                                                </div>
                                                                <div className="text-primary text-sm md:text-base xl:text-xl">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }

                                            {vps_params?.isHA ?
                                                <div className={`pt-5`} >
                                                    {allClusterHaList && allClusterHaList.map((cluster, i) => {
                                                        return <HACluster
                                                            key={cluster.id}
                                                            concertPack={concertPack}
                                                            creating={creating}
                                                            isTrial={isTrial}
                                                            currentCluster={cluster}
                                                            user={user}
                                                            number={i}
                                                            // canAddControlPlane={i === 0}
                                                            last={allClusterHaList.length === i + 1}
                                                            setHAlistCLuster={setHAlistCLuster}
                                                            allClusterHaList={allClusterHaList}
                                                            addHACluster={addHACluster}
                                                            deleteHACluster={deleteHACluster}
                                                            canDelete={allClusterHaList.length > 1}
                                                        />
                                                    })}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div>
                                            {
                                                isTrial ?
                                                    <TrialBottom
                                                        creating={creating}
                                                        paypal={paypal}
                                                        packSelected={packSelected}
                                                        vps_params={vps_params}
                                                        handleChange={handleChange}
                                                    />
                                                    :
                                                    isPromo ?
                                                        <PromoBottom
                                                            creating={creating}
                                                            paypal={paypal}
                                                            packSelected={packSelected}
                                                            vps_params={vps_params}
                                                            handleChange={handleChange}
                                                            codeApplied={codeApplied}
                                                            onApplyCode={onApplyCode}

                                                        />
                                                        :
                                                        <AddBotom
                                                            isKube
                                                            setBottomCalculedHeight={props.setBottomCalculedHeight}
                                                            creating={creating}
                                                            paypal={paypal}
                                                            packSelected={packSelected}
                                                            totalPrice={totalPrice()}
                                                        />

                                            }
                                        </div>

                                    </form>
                                    :
                                    <div className='flex justify-center pt-48'>
                                        <Spiner fontSize={50} />
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </>
            </div>
            {user && !user?.EmailConfirmed ?
                <UserAccountConfirmPrompt user={user} />
                :
                null
            }

        </div>
    );
}


function HACluster(props) {
    const { currentCluster } = props
    const [hoverServerName2, setHoverToolTopServerName2] = useState(null)

    const canAddControlPlane = (i) => {
        const filtered = currentCluster?.nodes?.filter(n => n.isMaster)
        return i === filtered.length - 1
    }

    function handleChange(ev) {
        const { name, value } = ev.target

        const { allClusterHaList } = props
        const index = allClusterHaList?.map(e => e.id).indexOf(currentCluster?.id)
        const dataCluster = {
            ...currentCluster,
            [name]: value
        }
        allClusterHaList[index] = dataCluster
        props.setHAlistCLuster([...allClusterHaList])
    }

    const deletable = (node) => {
        if (node?.isMaster) {
            const filtered = currentCluster?.nodes?.filter(n => n.isMaster)
            if (filtered.length === 1) return false
            return filtered[filtered.length - 1]?.id === node.id
        }
        else {
            const filtered = currentCluster?.nodes?.filter(n => !n.isMaster)
            if (filtered.length === 1) return false
            return filtered[filtered.length - 1]?.id === node.id
        }
    }
    function onAddNodeItemHA(controlPlane) {

        const { allClusterHaList } = props
        const { nodes } = currentCluster
        const index = allClusterHaList?.map(e => e.id).indexOf(currentCluster?.id)
        if (controlPlane) {
            const filtered = currentCluster?.nodes?.filter(n => n.isMaster)

            nodes.splice(
                filtered.length,
                0,
                {
                    id: Utils.generateId(),
                    isMaster: true
                }
            )
        } else {
            nodes.push(
                { id: Utils.generateId() }
            )
        }

        const dataCluster = {
            ...currentCluster,
            nodes: nodes
        }
        allClusterHaList[index] = dataCluster
        props.setHAlistCLuster([...allClusterHaList])
    }
    function deleteNodeHA(nodeId) {
        const { allClusterHaList } = props
        const { nodes } = currentCluster

        const index = allClusterHaList?.map(e => e.id).indexOf(currentCluster?.id)

        const dataCluster = {
            ...currentCluster,
            nodes: nodes.filter(node => node.id !== nodeId)
        }
        allClusterHaList[index] = dataCluster
        props.setHAlistCLuster([...allClusterHaList])
    }

    function setListNode(newNodeList) {
        const { allClusterHaList } = props
        const index = allClusterHaList?.map(e => e.id).indexOf(currentCluster?.id)
        const dataCluster = {
            ...currentCluster,
            nodes: newNodeList
        }
        allClusterHaList[index] = dataCluster
        props.setHAlistCLuster([...allClusterHaList])
    }
    function setDataCluster(data) {
        const { allClusterHaList } = props
        const index = allClusterHaList?.map(e => e.id).indexOf(currentCluster?.id)
        const dataCluster = {
            ...data
        }
        allClusterHaList[index] = dataCluster
        props.setHAlistCLuster([...allClusterHaList])
    }



    return (
        <div className={`py-6`}>
            <div className={''}>
                <ServerLocation
                    label={"Choose your HA Cluster-" + (props?.number + 1) + " location"}
                    vps_params={currentCluster}
                    setVpsParams={setDataCluster}
                />
            </div>
            <div className={'relative -mt-7 '}>

                {currentCluster?.nodes && currentCluster?.nodes.map((node, i) => {
                    return (
                        <NodeSelector
                            key={i}
                            concertPack={props.concertPack}
                            creating={props.creating}
                            // onClaculFlavor={onClaculFlavor}
                            node={node}
                            isTrial={props.isTrial}
                            setVpsParams={currentCluster}
                            user={props.user}
                            listNode={currentCluster?.nodes}
                            setListNode={setListNode}
                            onAddNodeItem={(isControlPlane) => onAddNodeItemHA(isControlPlane)}
                            deleteNode={deleteNodeHA}
                            vps_params={currentCluster}
                            canAddControlPlane={() => canAddControlPlane(i)}
                            first={i === 0}
                            forHA={true}
                            canDeleted={deletable(node)}
                            last={i + 1 === currentCluster?.nodes?.length}
                            title={node?.isMaster ?
                                "Choose your control plane " + ("" + (i + 1)).padStart(2, '0') + " plan "
                                :
                                "Choose your node-" + ("" + (
                                    i + 1 - currentCluster?.nodes?.filter(n => n.isMaster).length
                                )).padStart(2, '0') + " plan"
                            }
                        />
                    )
                })}

                <AddServiceSectionTitle
                    title={"HA Cluster-" + (props?.number + 1) + " Informations"}
                />

                <div className={'border-b-2  dark:border-gray-800  px-20 mx-auto pt-10  '}>
                    <div className=" flex flex-wrap -mx-3 mb-6">
                        <div className="w-full xl:w-[40%] px-3 mb-6 md:mb-0">
                            <LabelAdd
                                htmlFor={"HA-serv-name" + currentCluster?.id}
                                placeholder={"Identify your HA cluster"}
                            />
                            <Tooltip open={hoverServerName2} title="Must not contain spaces, special characters or symbols">
                                <InputTextAdd
                                    idx={"HA-serv-name" + currentCluster?.id}
                                    value={currentCluster.server_name}
                                    name="server_name"
                                    pattern="[A-Za-z0-9]{1,}"
                                    onChange={handleChange}
                                    required
                                    hover
                                    onHover={(state) => setHoverToolTopServerName2(state)}
                                    disabled={props.creating}
                                    placeholder='Your instance resources must have unique names'
                                />
                            </Tooltip>
                        </div>
                    </div>

                    <div style={{ display: !props.last && 'none' }} className="py-6 flex items-center flex-wrap justify-center gap-3">

                        <div onClick={props.addHACluster}
                            className="cursor-pointer flex flex-col text-center w-36 justify-center"
                        >
                            <HiOutlinePlusCircle className={'mx-auto my-auto text-6xl 2xl:text-9xl text-primary dark:text-darkTextColor '} />
                            <span className="font-semibold text-sm 2xl:text-base">Add HA Cluster</span>
                        </div>

                        {props?.canDelete &&
                            <div onClick={() => props.deleteHACluster(currentCluster?.id)}
                                className="cursor-pointer flex flex-col text-center w-36 justify-center"
                            >
                                <IoMdRemoveCircleOutline className={'mx-auto my-auto text-6xl 2xl:text-9xl text-red-500 dark:text-darkTextColor '} />
                                <span className="font-semibold text-red-500 text-sm 2xl:text-base">Delete HA Cluster</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function NodeSelector(props) {
    const rand = Utils.generateId()
    const [clicked, setClicked] = useState(null)
    const [selected, setSelected] = useState(null)
    const [packInfo, setPackInfo] = useState(null)
    // const [name] = useState("")
    const [hoverServerName, setHoverToolTopServerName] = useState(null)

    function setNodeName(value) {
        const index = props.listNode?.map(e => e.id).indexOf(props.node.id)
        const { listNode } = props
        listNode[index] = {
            ...props?.node,
            name: value,
        }
        props.setListNode([...listNode])

    }

    function onClaculFlavor(pack) {
        const { listNode } = props
        const index = props.listNode?.map(e => e.id).indexOf(props.node.id)
        listNode[index] = {
            ...props?.node,
            plan_id: (props.isTrial ? pack?.PaypalPackIdFree : pack?.PaypalPackId),
            vpsTypeId: pack?.Id,
            pack: pack,
            isMaster: props?.first || props.node?.isMaster,
        }
        props.setListNode([...listNode])
    }

    return (
        <div className="pt-5 relative z-[0]">
            <div
                onClick={(e) => props.deleteNode(props.node?.id)}
                style={{ display: !props?.canDeleted && 'none' }}
                className="absolute top-2 right-0 bg-gray-100 border-rounded cursor-pointer p-2"
            >
                <DeleteFilled className="text-red-500 text-2xl " />
            </div>
            <AddServiceSectionTitle
                title={props.title}
            />
            {props?.first && props.withAdmin ?
                <DeployAdAdmin
                    title="Choose your instance plan"
                    creating={props?.creating}
                    vps_params={props?.vps_params}
                    setVpsParams={props?.setVpsParams}
                />
                :
                null
            }

            <div className=' text-center '>
                <div className="relative">
                    <img
                        src={props.node?.isMaster ? controlPlane : nodeSelector}
                        alt=""
                        className='mx-auto mt-4 flex w-[40px] md:w-[55px] xl:w-[65px] 2xl:w-[75px]'
                    />
                    <div className='flex justify-center mt-3 2xl:mt-0 text-left 2xl:absolute 2xl:left-[56%] 2xl:top-[1px]'>
                        <div className='w-[400px]'>
                            <LabelAdd
                                htmlFor={"nodeName" + rand}
                                placeholder={"Identify your Node"}
                            />
                            <Tooltip open={hoverServerName} title="Must not contain spaces, special characters or symbols">
                                <InputTextAdd
                                    idx={"nodeName" + rand}
                                    value={props?.node?.name}
                                    name={"name_" + rand}
                                    pattern="[A-Za-z0-9]{1,}"
                                    onChange={(e) => setNodeName(e.target.value)}
                                    required
                                    hover
                                    onHover={(state) => setHoverToolTopServerName(state)}
                                    disabled={props.creating}
                                    placeholder='Your node resources must have unique names'
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b-2 dark:border-gray-800">
                <div className=" mx-auto justify-center gap-3 xl:gap-6 2xl:gap-8 items-center pb-5  flex flex-wrap   mt-5">
                    <VpsPack
                        concertPack={props.concertPack}
                        setSelected={setSelected}
                        creating={props.creating}
                        clicked={clicked}
                        setClicked={setClicked}
                        onClaculFlavor={onClaculFlavor} 
                        setPackPaypal={() => { }}
                        selected={selected}
                        noMxauto
                        last={props.last}
                        onAddNodeItem={props.onAddNodeItem}
                        isMaster={props.node?.isMaster}
                        displayAddPlane={
                            !(
                                !props?.last
                                &&
                                !(
                                    props.canAddControlPlane()
                                )
                            )
                        }
                    />
                </div>
                <div
                    className={`pb-3 flex justify-center  2xl:hidden`}
                    style={{
                        display: (
                            !props?.last
                            &&
                            !(
                                props.canAddControlPlane()
                            )
                        )
                            &&
                            'none'
                    }}
                >
                    <div onClick={() => props.onAddNodeItem(props.node?.isMaster)}
                        className="cursor-pointer flex flex-col text-center w-28 justify-center"
                    >
                        <HiOutlinePlusCircle className={'mx-auto my-auto text-5xl 2xl:text-8xl text-primary dark:text-darkTextColor '} />
                        <span className="font-semibold text-sm 2xl:text-base">
                            {props?.last ?
                                "Add New Node" :
                                props.node?.isMaster ?
                                    "Add Control Plane"
                                    :
                                    ""
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
