import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import { AddCloudServiceForm } from 'components/vps/AddCloudServiceForm';
import { VpsPack } from 'components/vps/Packages';

import almalinux from '../../assets/img/almalinux.png'
import centos from '../../assets/img/centos.png'
import debian from '../../assets/img/debian.png'
import docker from '../../assets/img/docker.png'
import linux from '../../assets/img/linux.png'
import ubuntu from '../../assets/img/ubuntu.png'
import { AddServiceSectionTitle } from '../../components/Bloc';
import { Toast } from '../../components/Notify';
import VpsImage from '../../components/VpsImage'
import UserMgt from '../../lib/user_managment';
import VPSapi from '../../lib/vps_service';
import Utils from '../../utils';
import  DeployServerWrapper from '../DeployServerWrapper';

const imgs = {
    ubuntu,
    debian,
    almalinux,
    docker,
    linux,
    centos
}


export default function AddServer(props) {
    const { isTrial, isPromo} = props
    const images = useSelector(app => { 
        const imgs = app.vps.images
        const OS = ["Ubuntu", "AlmaLinux", "Debian", "CentOS"];
        const Tab = []
        if (app.vps.images) {
            for (let i = 0; i < OS.length; i++) {
                const os = OS[i];
                const concerts = imgs.filter(img => img.name.toLowerCase().includes(os.toLowerCase()))
                Tab.push({ name: os, list: concerts, id: i })
            }
            return Tab
        }
        return null;
    })

    

    const { vps_packages } = useSelector(app => app.vps)
    
    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [clickedImage, setClickedImage] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [paypalpack, setPackPaypal] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)
    const [codeApplying, setCodeApplying] = useState(false)
    const [link, setlink] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        image: null,
        username: "",
        location: null,
        password: "",
        country: "canada",
        trialCode: null,
        promoCode: null,
        isWithPromo: isPromo,
        isWithTrial: isTrial,
        vpsTypeId: null,
        isAdminFreeDeployment: null
    })


    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)



    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }


    async function loadImages() {
        !images && VPSapi.image_list()
    }
    async function getVpsTypes() {
        if (!vps_packages) {
            const r = await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        }

        return vps_packages
    }




    useEffect(() => {

        if (Utils.getUrlParam("code")) {
            setVpsParams({ ...vps_params, trialCode: Utils.getUrlParam("code"), promoCode: Utils.getUrlParam("code") })
            onApplyCode(Utils.getUrlParam("code"))
        }
        getVpsTypes().then(vps_packages => {
            if (Utils.getUrlParam('prefer')) {
                const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                if (pack) {
                    setClicked(parseInt(selectedIdVpsTypes));
                    onClaculFlavor(pack)
                    setPackPaypal(pack.Name);
                }
                window.localStorage.removeItem('@user_first_service')
            }
        })
        loadImages()
    }, [])



    async function onApplyCode(code){
        if(!isPromo) return
        try{
            let c = code || vps_params.promoCode
            if(c && c.trim() !== ""){
                setCodeApplying(true)
                const result = await UserMgt.check_promo_code(c);
                setCodeApplying(false)
                if(result && result.code){
                    return setCodeApplied(result?.code)
                }else{
                  return Toast.fire({
                        icon: 'error',
                        title: "Invalid promotion code"
                    })  
                }
            }else{
                return Toast.fire({
                    icon: 'error',
                    title: "Please enter promotion code first !"
                })
            }

        }catch(e){
            console.log('error applying code', e)
        }

        return Toast.fire({
            icon: 'error',
            title: "An error occurred, try again or contact us !"
        })
    }

    async function deployVps(paymentId, dataPayment) {
        console.log('vps_params vps_params', vps_params)
        const result = await UserMgt.create_vps({ ...vps_params, paymentId }, dataPayment)
        setCreating(false)
        if (!result || result.error || result.status !== "success") {
            Toast.fire({
                icon: 'error',
                title: result.message || "An error occurred while running. If this persist, please contact us"
            })
        } else {
            const product_bought = [
                "product_bought", {
                    type: "VSP",
                    name_package: paypalpack,
                    price_package: packSelected.Price,
                    price_paid: calCulFinalPrice(),
                    admin_deployed: vps_params.isAdminFreeDeployment,
                    trial: !!vps_params.trialCode ? vps_params.trialCode : false,
                    promo: !!vps_params.promoCode ? vps_params.promoCode : false,
                },
                "green"
            ]
            window.$crisp.push(["set", "session:event", [[ product_bought ]]]);
            vps_params.trialCode ?
                window.$crisp.push(["set", "session:segments", [["vps", "trial", "lead"]]]) :
                window.$crisp.push(["set", "session:segments", [["vps", "paying_customer", "lead"]]])
            Toast.fire({
                icon: 'success',
                title: result.message || "VPS Instance created"
            })
            const links = result?.result?.links
            console.log('links links links', links);
            const link = links && links.length && links.find(l => l.rel === "approve")
            if(link){
                setlink(link?.href)
                window.open(link?.href, "Confirm", "width=500,height=500, fullscreen=yes")
            }
            if(result&& !result.needToConfirm){
                dispatch({ type: "@DEPLOYED_VPS", state: true })
                await UserMgt.get_user_vps()
                navigate('/compute/vps')
            }
        }

    }
    async function onPay(ev) {
        console.log('vps_params vps_params', vps_params)
        ev.preventDefault()
        if(link){
            return  window.open(link, "Confirm subscription", "width=500,height=500, fullscreen=yes")
         }
        if (!vps_params.image) {
            return Toast.fire({
                icon: 'error',
                title: "Image not selected"
            })
        }
        if (!vps_params.vpsTypeId) {
            return Toast.fire({
                icon: 'error',
                title: "Package not selected"
            })
        }
        if (!vps_params.location) {
            return Toast.fire({
                icon: 'error',
                title: "Service location not selected"
            })
        }
        console.log('deploiement params ****', vps_params)

        if (vps_params.password.length < 6) {
            return Toast.fire({
                icon: 'error',
                title: "Password must be at least 6 characters"
            })
        }
        if (Utils.testPassworsVps(vps_params.password)) {
            return Toast.fire({
                icon: 'error',
                title: "Password should not contains empty space"
            })
        }
        if (vps_params.passwordCon !== vps_params.password) {
            return Toast.fire({
                icon: 'error',
                title: "Passwords did not match"
            })
        }
        if (isPromo && !codeApplied) {
            return Toast.fire({
                icon: 'error',
                title: "You should apply promo code !"
            })
        }

        setCreating(true)
        if(!vps_params.isAdminFreeDeployment && !Utils.isDemo()){
            if(!isPromo){
                console.log( 'vps_params.trialCode ', vps_params.trialCode)
                if(vps_params.trialCode && isTrial){
                    const r = await UserMgt.check_trial_code(vps_params.trialCode)
                    console.log('TRAIL RESUL ', r)
                    const isValid = r && r.code && (!!r.code.IsMulti ||  !(!!r.code.IsUsed ));
                    if(!isValid ){
                        setCreating(false)
                        // setPaypal(false) 
                        return Toast.fire({
                            icon: 'error',
                            title: "Trial code is not valid !"
                        })
                    }
                }
                setPaypal(true)

            }else{
                return deployVps()
            }
        }else{
            return deployVps()
        }
    }

    function onClaculFlavor(pack) {
        if(pack) setPack({...pack})
        else setPack(null)
        setVpsParams({ 
                ...vps_params,  
                plan_id: (isTrial? pack?.PaypalPackIdFree : pack?.PaypalPackId),
                vpsTypeId: pack?.Id
            }
        )
        

    }


    function onDismiss() {
        setCreating(false)
        setPaypal(false)

    }
    function createPaymentAndDeploy(paymentID, dataPayment) {
        setPaypal(false)
        console.log('data payment start, createPaymentAndDeploy', paymentID, dataPayment)
        const dataPayment2 = {
            ...dataPayment,
            withFirstTrial: isTrial,
            amountPaid: parseFloat(packSelected?.Price),

        }
        
        return deployVps(paymentID, dataPayment2)



    }
    function calCulFinalPrice(){
        if(packSelected){

            return parseFloat((packSelected.Price) * (1 - (codeApplied ? codeApplied.Percent / 100 : 0))).toFixed(2)
        }else{
            return 0;
        }
    }


    

    const concertPack = vps_packages &&
        vps_packages.filter(p => p.service?.task_name === "vps")
            .sort((a, b) => parseInt(a.Price) - parseInt(b.Price))
    return (
        <DeployServerWrapper
            createPaymentAndDeploy={createPaymentAndDeploy}
            paypal={paypal}
            packSelected={packSelected}
            onDismiss={onDismiss}
            isTrial={isTrial}
            isPromo={isPromo}
            calCulFinalPrice={calCulFinalPrice}
            vps_params={vps_params}
            setVpsParams={setVpsParams}
            creating={creating}
            concertPack={concertPack}
            onPay={onPay}
            handleChange={handleChange}
            onApplyCode={onApplyCode}
            codeApplied={codeApplied}
            codeApplying={codeApplying}
        >   
            <div> 
                <div className=" 2xl:gap-10 md:gap-6 gap-3 mt-5 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap  justify-center ">
                    <VpsPack
                        concertPack={concertPack}
                        setSelected={setSelected}
                        creating={creating}
                        clicked={clicked}
                        setClicked={setClicked}
                        onClaculFlavor={onClaculFlavor}
                        setPackPaypal={setPackPaypal}
                        selected={selected}
                        noMxauto
                    />
                </div>    
                <AddServiceSectionTitle
                    title="Pick your instance image"
                />
                <div className="mx-auto relative  justify-center pb-5 border-b-2 dark:border-gray-800 flex flex-wrap 2xl:gap-10 md:gap-6 gap-3  mt-5">
                    {images && images.filter(image => image.name !== "KPS_cloud").map((image, k) =>
                        <VpsImage
                            key={k}
                            image={image}
                            creating={creating}
                            clickedImage={clickedImage}
                            setClickedImage={setClickedImage}
                            setVpsParams={setVpsParams}
                            vps_params={vps_params}
                            setSelectedImage={setSelectedImage}
                            selectedImage={selectedImage}
                            uri={imgs[Object.keys(imgs).find(k => image.name.toLowerCase().includes(k)) || 'linux']}
                        />


                    )}
                </div>
                <AddServiceSectionTitle
                    title="Server Informations"
                />
                <div className={'w-full md:w-5/6 xl:w-[75%] 2xl:w-[70%]  mx-auto pt-10 '}>
                    <AddCloudServiceForm
                        creating={creating}
                        paypal={paypal}
                        vps_params={vps_params}
                        handleChange={handleChange}
                    />
                </div>
            </div> 
        </DeployServerWrapper>
    );
}




