import {  useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import VPSapi from '../../lib/vps_service';
import { Toast } from '../../components/Notify';
import { 
        InputTextAdd, 
        LabelAdd, 
        AddServiceSectionTitle
} from '../../components/Bloc';
import UserMgt from '../../lib/user_managment';
import { useNavigate } from "react-router-dom";
import Utils from '../../utils'; 
import { KwafPack } from '../../components/vps/Packages';
import { Checkbox, Tooltip } from 'antd';
import  DeployServerWrapper from '../DeployServerWrapper';
import K8S from '../../lib/k8s';


export default function KwafNew(props) {
    const {isTrial, isPromo} = props
    const { vps_packages } = useSelector(app => app.vps)
    const user = useSelector(app => app?.user?.user)
    const [hoverServerName, setHoverToolTopServerName] = useState(false)
    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [paypalpack, setPackPaypal] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)
    const [link, setlink] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        image: "K-WAF",
        // email: "",
        location: null,
        trialCode: null,
        promoCode: null,
        isWithPromo: isPromo,
        isAdminFreeDeployment: null
    })

 
    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)

    

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }


    async function getVpsTypes() {
        if(!vps_packages) {
            const r =  await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        } 
            
        return vps_packages
    }
 


    useEffect(() => {
        if (Utils.getUrlParam("code")) {
            setVpsParams({ ...vps_params, trialCode: Utils.getUrlParam("code"), promoCode: Utils.getUrlParam("code") })
            onApplyCode(Utils.getUrlParam("code"))
        }

        getVpsTypes().then(vps_packages => {
            if(Utils.getUrlParam('prefer')){
                const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                if(pack){
                    setClicked(parseInt(selectedIdVpsTypes));
                    onClaculFlavor(vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes)))
                    setPackPaypal(pack.Name);

                }
                window.localStorage.removeItem('@user_first_service')
            }
        })
    }, [])
 

    
    async function onApplyCode(code){
        if(!isPromo) return
        try{
            let c = code || vps_params.promoCode
            if(c && c.trim() !== ""){
                const result = await UserMgt.check_promo_code(c);
                if(result && result.code){
                    return setCodeApplied(result?.code)
                }else{
                  return Toast.fire({
                        icon: 'error',
                        title: "Invalid promotion code"
                    })  
                }
            }else{
                return Toast.fire({
                    icon: 'error',
                    title: "Please enter promotion code first !"
                })
            }

        }catch(e){
            console.log('error applying code', e)
        }

        return Toast.fire({
            icon: 'error',
            title: "An error occured. If this persist, please tell us"
        })
    }

    async function deployVps(paymentId, dataPayment) {
      
        const result = await UserMgt.create_vps({ ...vps_params, paymentId }, dataPayment)
        setCreating(false)
        if (!result || result.error || result.status !== "success") {
            Toast.fire({
                icon: 'error', 
                title: result.message || "An error occurred while running. If this persist, please contact us"
            })
        } else {
            const product_bought = [
                "product_bought", {
                    type: "K-WAF",
                    name_package: paypalpack,
                    price_package: packSelected.Price,
                    price_paid: calCulFinalPrice(),
                    admin_deployed: vps_params.isAdminFreeDeployment,
                    trial: !!vps_params.trialCode ? vps_params.trialCode : false,
                    promo: !!vps_params.promoCode ? vps_params.promoCode : false,
                },
                "green"
            ]
            window.$crisp.push(["set", "session:event", [[ product_bought ]]]);
            vps_params.trialCode ?
                window.$crisp.push(["set", "session:segments", [["kwaf", "trial", "lead"]]]) :
                window.$crisp.push(["set", "session:segments", [["kwaf", "paying_customer", "lead"]]])
            const links = result?.result?.links
            const link = links && links.length && links.find(l => l.rel === "approve")
            Toast.fire({
                icon: 'success',
                title: result.message || "K-WAF service created"
            })
            if(link){
                setlink(link?.href)
                window.open(link?.href, "Confirm", "width=500,height=500, fullscreen=yes")
            }
            if(result&& !result.needToConfirm){
                Toast.fire({
                    icon: 'success', 
                    title: "K-WAF service created"
                })
                await UserMgt.get_user_vps()
                navigate('/security/kwaf')
            }
        }

    }
    async function onPay(ev) {
        ev.preventDefault()
        if(link){
            return  window.open(link, "Confirm subscription", "width=500,height=500, fullscreen=yes")
         }
      
        if (!vps_params.vpsTypeId) {
            return Toast.fire({
                icon: 'error',
                title: "Package didn't selected."
            })
        }
        if (!vps_params.location) {
            return Toast.fire({
                icon: 'error',
                title: "Service location not selected"
            })
        }
        if (isPromo && !codeApplied) {
            return Toast.fire({
                icon: 'error',
                title: "You should apply promo code !"
            })
        }
        setCreating(true)
        if(!vps_params.isAdminFreeDeployment && !Utils.isDemo()){
            if(!vps_params.plan_id){

                const result = await K8S.createPlan(null , packSelected?.Price);
                setVpsParams({
                        ...vps_params,
                        plan_id: result?.id
                    }
                )
                setPack({
                    ...packSelected, 
                    PaypalPackId: !isTrial && result?.id,
                    PaypalPackIdFree: !isTrial && result?.id
                })
            }
            if(!isPromo){
                if(vps_params.trialCode && isTrial){
                    const r = await UserMgt.check_trial_code(vps_params.trialCode)
                    const isValid = r && r.code && (!!r.code.IsMulti ||  !(!!r.code.IsUsed ));
                    if(!isValid ){
                        setCreating(false)
                        setPaypal(false)
                        return Toast.fire({
                            icon: 'error',
                            title: "Trial code is not valid !"
                        })
                    }
                }
                setPaypal(true)
            }else{
                return deployVps()
            }
        }else{
            return deployVps()
        }
    }

    function onClaculFlavor(pack) {
        if(pack) setPack({...pack})
        else setPack(null)
        setVpsParams({ 
            ...vps_params,  
            vpsTypeId: pack?.Id,  
            plan_id: (isTrial? pack?.PaypalPackIdFree : pack?.PaypalPackId) 
        })
    }

    function calCulFinalPrice(){
        if(packSelected){

            return parseFloat((packSelected.Price) * (1 - (codeApplied ? codeApplied.Percent / 100 : 0))).toFixed(2)
        }else{
            return 0;
        }
    }

    
    function onDismiss(){
        setCreating(false)
        setPaypal(false)

    }
    function createPaymentAndDeploy(paymentID, dataPayment){
        setPaypal(false)
        const dataPayment2 = {
            ...dataPayment,
            withFirstTrial: isTrial,
            amountPaid:  parseFloat(packSelected?.Price),

        }
        return  deployVps(paymentID, dataPayment2)
    }
    //PaypalPackId
    const concertPack = vps_packages &&  
                        vps_packages.filter(p => p.service?.task_name === "kwaf")
                        .sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price))
    return (
        <DeployServerWrapper
            createPaymentAndDeploy={createPaymentAndDeploy}
            paypal={paypal}
            packSelected={packSelected}
            onDismiss={onDismiss}
            isTrial={isTrial}
            isPromo={isPromo}
            calCulFinalPrice={calCulFinalPrice}
            vps_params={vps_params}
            setVpsParams={setVpsParams}
            creating={creating}
            concertPack={concertPack}
            onPay={onPay}
            handleChange={handleChange}
            isSecurity
        >   
            <div>
                <div className=" 2xl:gap-10 md:gap-6 gap-3 mt-5 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap  justify-center ">
                    <KwafPack
                        concertPack={concertPack} 
                        setSelected={setSelected} 
                        creating={creating} 
                        clicked={clicked} 
                        setClicked={setClicked} 
                        onClaculFlavor={onClaculFlavor} 
                        setPackPaypal={setPackPaypal} 
                        selected={selected} 
                    />
                </div>

            
                <AddServiceSectionTitle
                    title="K-WAF Informations"
                />

                <div className={'w-full md:w-5/6 xl:w-[75%] 2xl:w-[70%]  mx-auto pt-10 pb-[15px]'}>
                    <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                        <LabelAdd
                            htmlFor="serv-name"
                            placeholder={"Identify your K-WAF instance"}
                        />
                        <Tooltip open={hoverServerName}   title="Must not contain spaces, special characters or symbols">
                            <InputTextAdd
                                idx="serv-name"
                                value={vps_params.server_name}
                                name="server_name"
                                pattern="[A-Za-z0-9]{1,}"
                                onChange={handleChange}
                                required
                                hover
                                onHover={(state) => setHoverToolTopServerName(state)}
                                disabled={creating}
                                placeholder='Your instance resources must have unique names'
                            />
                        </Tooltip>
                    </div>
                </div>
            </div> 
        </DeployServerWrapper>
    );
}



