import { Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'antd';

import { Toast } from '../components/Notify'; 
import { CancelButton, OkButton } from './OkButton';
import Utils from '../utils';
import { save_deletable_service } from 'reducer/action/coreActions';
import UserMgt from '../lib/user_managment';
import K8S from '../lib/k8s';
import VPC from '../lib/vpc';
import STORAGE from '../lib/storage';

import { STANDARD_ERROR_MESSAGE } from 'constants/message';

const { Text  } = Typography; 

export function Delete({ service, returnLink }) {
    const [loanding, setloading] = useState(false)
    const { messageApi } = useSelector(app => app.core)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function onCancel() {
        dispatch(save_deletable_service(null, null))
    }

    const onDeleteMethod = async() => {
        setloading(true)
        const serviceType = service?.service?.task_name
        const isStorage = serviceType === "volume_blocks_torage"
        let response;
        if(isStorage || (Utils.isAdmin() && service?.IsDeployedByAdmin) || (service?.VpsStatus === "Pending" && service?.isWithPromotion) || service?.isDemo){
            if(serviceType === "kskubhybr"){
                response = await K8S.deleteInstance(service?.id)
            }else if(["vpc"].includes(serviceType)){
                response = await VPC.deleteNet(service?.id)
            }else if(isStorage){
                const data = {
                    action: "forceDelete",
                    vlumeId: service?.id
                }
                response = await STORAGE.mkAction(data)
            }
            else{
                response = await UserMgt.deleteServer(service?.Id)
            }
            if(response === 204 || isStorage && response && !response.error){
                Utils.onNotifyCrisp(service)
                messageApi.success('Service deleted.')
                if(["vpc"].includes(serviceType)){
                    await VPC.getAll()
                }else if(serviceType === "kskubhybr"){
                    await K8S.getK8sInstance()
                }else if(isStorage){
                    await STORAGE.list()
                }
                else{
                    await UserMgt.get_user_vps()
                }
            }else if(response === 403){
                messageApi.error('You are not allowed to access to this ressource.')
            }else{
                messageApi.error(isStorage && response?.message || 'Vps could not be deleted, please try again or contact us!')
            }
        }else{
            const result = await UserMgt.update_subcription(service.Id, "suspend")
            if (result && result.status === "error") {
                messageApi.error(result?.message || STANDARD_ERROR_MESSAGE)
            }else{
                Utils.onNotifyCrisp(service)
                messageApi.success('Your subscription has been cancelled!')
            }
        }
        setloading(false)
        onCancel()
        if(returnLink) navigate(returnLink)
    }

    return (
        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={700}
	        open={service}
	    >
	        <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            Delete this service?
                        </h4>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
                        <p className="pt-5">
                            <span className="text-orange-600 font-semibold">Warning: </span> This process will automatically remove this service.
                            <br />
                            <br />
                            Other ressources that rely on this service might be affected.
                            <br />
                        </p>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => onCancel()}>
	                        No, Cancel
	                    </Button>
                        
                        <Button 
                            loading={loanding} 
                            size="large" 
                            onClick={onDeleteMethod}
                            className="bg-red-500" 
                            danger
                            style={{color: "white"}}
	                    >
	                        Yes, Delete
	                    </Button>
            	</div>
            </div>
	    </Modal>

    )
}
// <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={id} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
//     <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
//         <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
//             <div className="dark:bg-bgPagedark modal-body relative py-6 text-center">
//                 <span className="text-3xl">Delete this {!volume ? "instance": "volume"}?</span>
//                 <p className="pt-5">
//                     <span className="text-orange-600 font-semibold">Warning: </span> This process will automatically remove this {!volume ? "instance": "volume"}.
//                     <br />
//                     <br />
//                     Other ressources that rely on this {!volume ? "instance": "volume"} might be affected.
//                     <br />
//                 </p>
//                 <div className="pt-10 gap-4 flex justify-center items-center">
//                     <CancelButton title={"No, Cancel"} fun={onCancel} />
//                     {"  "}
//                     <OkButton click title="Yes, Delete" fun={onDeleteMethod} server={server} loanding={loanding} />
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>


export function Reboot({ id, server }) {
    const [loanding, setloading] = useState(false)

    function onCancel() {
        return window.$("#" + id).modal('hide')
    }

    async function onRebootMethod(id) {
        setloading(true)
        console.log('start reboot vps')
        const result =  await UserMgt.onVpsAction("reboot", server?.Id)
        console.log('result onReboot', result)
        setloading(false)
        onCancel()
        if (result && (result.status === "success" || result.statusCode === "200" || !result.error)) {
            UserMgt.get_user_vps()
            return Toast.fire({
                icon: 'success',
                title:  "Server " + server.OpenstackInstanceName + " rebooted" //result.details || result.message || "Server " + server.OpenstackInstanceName + " rebooted"
            })
        } else {

            Toast.fire({
                icon: 'error',
                title: result?.message || "An error occured, if this persist, please tell us"
            })

        }

    }
    return (

        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={id} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">



                    <div className="dark:bg-bgPagedark modal-body relative py-6 text-center">
                        <span className="text-3xl">Reboot this instance?</span>

                        <p className="pt-5">
                            <span className="text-orange-600 font-semibold">Warning: </span> This proccess will make any website or service on your instance temporarily unavailable. <br />
                            Do you really want to reboot your instance ?

                            <br />

                        </p>
                        <div className="pt-10 gap-4 flex justify-center items-center">
                            
                            <CancelButton title={"No, Cancel"} fun={onCancel} />
                            {"  "}

                            <OkButton click title="Yes, Reboot" fun={onRebootMethod} server={server} loanding={loanding} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Stop({ id, server }) {
    const [loanding, setloading] = useState(false)

    function onCancel() {
        return window.$("#" + id).modal('hide')
    }
    async function onStopMethod() {
        setloading(true)
        console.log('start stop vps', id, server)
        const result = await UserMgt.onVpsAction("stop", server?.Id)
        console.log('result onStop', result)
        setloading(false)
        onCancel()
        if (result && (result.status === "success" || result.statusCode === "200" || !result.error)) {
            UserMgt.get_user_vps()
            return Toast.fire({
                icon: 'success',
                title: "Server " + server.OpenstackInstanceName + " stopped" //result.details || result.message || "Server " + server.OpenstackInstanceName + " stopped"
            })
        } else {

            Toast.fire({
                icon: 'error',
                title: result?.message || "An error occured, if this persist, please tell us"
            })

        }

    }
    return (

        <div className="modal fade fixed  top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={id} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                <div className="modal-content w-[800px] border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">



                    <div className="dark:bg-bgPagedark modal-body relative py-6 text-center">
                        <span className="text-3xl">Stop this instance?</span>

                        <p className="pt-5">
                            <span className="text-orange-600 font-semibold">Warning: </span> This proccess will make any website or service on your instance unavailable until your start it again.
                            <br />
                            <br />
                            Stopped instances continue to occur charges.
                            <br />
                            Delete your instances to stop occuring charges.
                            <br />
                            <br />
                            Do you really want to stop your instance?
                            <br />

                        </p>
                        <div className="pt-10 gap-4 flex justify-center items-center">
                            <CancelButton title={"No, Cancel"} fun={onCancel} />
                            {"  "}

                            <OkButton click title="Yes, Stop" fun={onStopMethod} server={server} loanding={loanding} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export function RBuildVpsConfirm({ id, server, onContinue }) {

    function onCancel() {
        return window.$("#" + id).modal('hide')
    }
    async function okRebuild() {
        onCancel()
        onContinue()
    }
    return (

        <div className="modal fade fixed  top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={id} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">



                    <div className="dark:bg-bgPagedark modal-body relative py-6 text-center">
                        <span className="text-3xl">Rebuild  this instance?</span>

                        <p className="pt-5">
                            <span className="text-orange-600 font-semibold">Warning: </span> This proccess will make any website or service on your instance unavailable.
                            <br />
                            <br />
                            This process will automatically remove this instance including all of its data and rebuild it.
                            <br />
                            <br />
                            Do you really want to rebuild your instance?
                            <br />

                        </p>
                        <div className="pt-10 gap-4 flex justify-center items-center">
                            <CancelButton title={"No, Cancel"} fun={onCancel} />
                            {"  "}

                            <OkButton click title="Yes, Rebuild" fun={okRebuild} server={server}  />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export function Start({ id, server }) {
    const [loanding, setloading] = useState(false)

    function onCancel() {
        return window.$("#" + id).modal('hide')
    }
    async function onStopMethod(id) {
        setloading(true)
        console.log('start stop vps')
        const result =  await UserMgt.onVpsAction("start", server?.Id)
        console.log('result onStop', result)
        setloading(false)
        onCancel()
        if (result && (result.status === "success" || result.statusCode === "200" || !result.error)) {
            UserMgt.get_user_vps()
            return Toast.fire({
                icon: 'success',
                title: "Server " + server.OpenstackInstanceName + " started" //result.details || result.message || "Server " + server.OpenstackInstanceName + " started"
            })
        } else {

            Toast.fire({
                icon: 'error',
                title: result?.message || "An error occured, if this persist, please tell us"
            })

        }

    }
    return (

        <div className="modal fade fixed  top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={id} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">



                    <div className="dark:bg-bgPagedark modal-body relative py-6 text-center">
                        <span className="text-3xl">Start this instance?</span>

                        <p className="pt-5">
                            Do you really want to start your instance?
                        </p>
                        <div className="pt-10 gap-4 flex justify-center items-center">
                            <CancelButton title={"No, Cancel"} fun={onCancel} />
                            {"  "}

                            <OkButton click title="Yes, Start" fun={onStopMethod} server={server} loanding={loanding} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function ModalConfirm({ item, server, idx, vpsmini, concernBlue, loadVps, credentials }) {
    const navigate = useNavigate()
    const [loanding, setloading] = useState(false)
    const [shoudlDIsplayResult, setShoul] = useState(false)
    const [data, setDataResult] = useState(null)
    const item2 = idx ? idx : item
    function onCancel() {
        return window.$("#" + item2).modal('hide')
    }

    const okInstall = async () => {
        if (vpsmini) {
            setloading(true)
            const d = {
                 payload: concernBlue
              }
            const res = await UserMgt.onVpsAction("micro_blueprint", server?.Id, d);
            console.log('res res microBlue', res, res?.error)
            if (res && !res?.error) {
                Toast.fire({
                    icon: 'success',
                    title: (!credentials &&  res?.message) || 'The blueprint has been deployed successfully'
                })
                if(credentials){
                    setDataResult(JSON.stringify(JSON.parse(res?.message), null, 2))
                }
                loadVps(true)
            } else {
                Toast.fire({
                    icon: 'error',
                    title: res?.message || "An error occured, if this persist, please tell us"
                })
            }
            setloading(false)
            if(!credentials){
                onCancel();
            }
        } else {
            onCancel();
            navigate("/compute/vps/" + server.Id + "/console?blueprint=" + item2)
        }
    }


    return (

        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" 
            id={item2} 
            data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" 
            aria-labelledby="exampleModalCenterTitle" 
            aria-modal="true" 
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">



                    <div className="dark:bg-bgPagedark modal-body relative py-6 text-center ">
                        <span className="text-3xl">
                            {!data ?
                                "Please confirm the install of "+item
                                :
                                "Please keep the app credentials secure"
                            }
                        </span>

                        <p className={"pt-5 px-5 " + (vpsmini && " px-5")}>

                            {!data ?                           
                                <>
                                    <span className="text-orange-600 font-semibold">Warning: </span>
                                    {!vpsmini && <>
                                        Do not interrupt the installation process and follow the instructions given.
                                        <br />
                                        <br />
                                        Please note any usernames, passwords and/or ports to open given at the end of the installation.
                                        <br />
                                        <br />
                                    </>}
                                    Other ressources that rely on this instance might by affected.
                                    <br />
                                    <br />
                                </>:
                                <div className='px-6 py-3 text-left font-semibold'>
                                    <p className='text-sm 2xl:textbase'>
                                        <span >DB Root Password</span>&nbsp;&nbsp;
                                        <Text code copyable className='font-bold text-primary' >
                                            {JSON.parse(data)?.db_root_password}
                                        </Text>
                                    </p>
                                    <p className='text-sm 2xl:textbase mt-2'>
                                        <span >DB User Password</span>&nbsp;&nbsp;
                                        <Text code copyable  className='font-bold text-primary'>
                                            {JSON.parse(data)?.db_wpuser_password}
                                        </Text>
                                    </p>
                                    {/* <pre>{data}</pre> */}
                                </div>
                            }

                        </p>
                        <div className="pt-10 flex gap-3 justify-center">
                            <CancelButton title={!data ? "No, Cancel" : "Okay"} fun={onCancel} />
                            {"  "}
                            {!data &&
                                <OkButton click title="Yes, Install" loanding={loanding}  fun={okInstall} />
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

