import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsGithub } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";

import Utils from '../utils'

export function SocialLogin(props){ 
    const { t } = useTranslation();
    const [Gaccess_token, setGaToken] = useState(null)

    const [onGoing, setOnGoing] = useState({case: ""})

    const Glogin = useGoogleLogin({
        onSuccess: (user) =>{
             console.log('Login success google', user)
             setGaToken(user.access_token)
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    async function callback(data, google){
        setOnGoing({
            case: google ? "google" : "github"
        })
        await props.onCallback(null, data)
        setOnGoing({
            case: ""
        })
        return
    }

    const onProcesWithGithub = () => {
        window.location.href = `https://github.com/login/oauth/authorize?scope=user:email%20read:user&client_id=${Utils.github_clien_id}&redirect_uri=${!props.signup ?  Utils.gith_redirect_uri : Utils.gith_redirect_uri2 }`
    } 
    useEffect(() => {
        const prevSelect = Utils.getUrlParam("next")
        Utils.sleep(2000).then(()=>{
            if(prevSelect === "google"){
                return Glogin()
            }else if(prevSelect === "github"){
                return onProcesWithGithub()
            }
        })
    }, [])
    useEffect(() => {

        const url = window.location.href;
        const hasCode = url.includes("?code=")
        if (hasCode) {
            const newUrl = url.split("?code=");
            window.history.pushState({}, null, newUrl[0]);
            const requestData = {
              code: newUrl[1],
              github: true
            };
            console.log('requestData requestData ', requestData)
            callback(requestData)
        
        }
        if(Gaccess_token){

            const requestData = {
                Gaccess_token: Gaccess_token,
                google: true
            };
            callback(requestData, true)
        }

    }, [Gaccess_token])

    return(
        <div className='flex flex-wrap flex-col pt-8'>
            <button
                className="
                    flex
                    gap-2
                    w-full
                    justify-center
                    items-center
                    rounded-xl
                    py-1
                    px-4
                    mb-3
                    border-gray-300
                    border-2
                    font-semibold
                "
                // href={`https://github.com/login/oauth/authorize?scope=user&client_id=${Utils.github_clien_id}&redirect_uri=${Utils.gith_redirect_uri}`}
                onClick={onProcesWithGithub} 
            >
                {(onGoing['case'] === "github") ?
                    <span
                        className='
                            flex
                            gap-2
                            w-full
                            justify-center
                            items-center
                        '
                    >
                        <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-primary"  fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>{t('Ongoing')} ...</span>
                    </span>
                    :
                    <span
                        className='
                            flex
                            gap-2
                            w-full
                            justify-center
                            items-center
                        '
                    >
                        <BsGithub />
                        {t("Sign " +(props.signup ? "up" : "in")+ " with")} GitHub
                    </span>
                }         
            </button>

            <button 
                className='
                    rounded-xl
                    py-1
                    px-4
                    mb-3
                    border-gray-300
                    border-2
                    font-semibold
                ' 
                onClick={() => Glogin()}
            > 
                {(onGoing['case'] === "google") ?
                    <span
                        className='
                            flex
                            gap-2
                            w-full
                            justify-center
                            items-center
                        '
                    >
                        <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-primary"  fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>{t('Ongoing')} ...</span>
                    </span>
                    :
                    <span
                        className='
                            flex
                            gap-2
                            w-full
                            justify-center
                            items-center
                        '
                    >
                        <FcGoogle />
                        {t("Sign " +(props.signup ? "up" : "in")+ " with")} Google
                    </span>
                }               
            </button>
        </div>
    )
}
