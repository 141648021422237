import React from 'react'
import Utils from '../../utils'
import { Tooltip } from 'antd';
import { OkButton } from '../OkButton';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LinkOutlined } from '@ant-design/icons';
import { CardServiceLayout, MiniCardServiceLayout, ServiceOptions } from 'layouts/ServiceLayout'

import k8sImage from '../../assets/img/k8/kubernetes-kp.webp'
import { KHKS_MANAGE_LINK } from 'constants/link';
import { ExtLink } from 'components/Bloc';


export function K8sCard({k8s}){
	const navigate = useNavigate()

    const masterCluster = k8s?.clusters?.find(cluster => cluster?.isPrimaryInstance)
    let totalNode = [];
    for (var i = 0; i < k8s?.clusters?.length; i++) {
        const instances = k8s?.clusters[i]?.instances
        totalNode = totalNode.concat(instances)
    }
	const nodeMaster = masterCluster && masterCluster.instances?.find(inst => !!inst.isKubeMaster && !!inst.isPrimarykubeMaster)

	const totalCPUArr = totalNode && totalNode?.map(inst => inst?.type?.Cpus).reduce((a, b) => a + b, 0)
	const totalRAMArr = totalNode && totalNode?.map(inst => inst?.type?.RamGb).reduce((a, b) => a + b, 0)
	const totalSSDArr = totalNode && totalNode?.map(inst => inst?.type?.DiskGb).reduce((a, b) => a + b, 0)
	
    const is = (state) => nodeMaster?.VpsStatus === state
    

	const onManage = async() => {
    	return navigate(KHKS_MANAGE_LINK(k8s.id)) 
    }



    return (
        <CardServiceLayout grayScale={!k8s?.is_deployed} server={k8s}>
            <div>
                <div className='border-b-2 dark:border-gray-800 pb-4 2xl:pb-8 pt-3'>
                    <div className='flex justify-between items-start'>
                        <div className='flex 2xl:gap-4 gap-2'>
                            <img src={k8sImage} alt="" className='w-14 h-14' />
                            <div>
                                <span className='2xl:text-xl text-base font-semibold text-primary dark:text-darkTextColor'>
                                	{masterCluster?.name}
                                </span> <br/>
                                <span className="2xl:text-base text-xs">{totalCPUArr+"CPU-"+totalRAMArr+"GB-"+totalSSDArr+"GB"}</span><br/>
                            </div> 
                        </div>
                        <div className='flex justify-end '>
                            <OkButton click fun={onManage} title="Manage" disabled={!k8s?.is_deployed}  server={nodeMaster} loanding={false}/>
                            &nbsp;&nbsp;&nbsp;
                            <ServiceOptions
                                options={[
                                    {label: "Download config", cunstomFun: () => !!k8s?.is_deployed && Utils.createK8SFile(k8s), display: !true && "none"},
                                    {label: "Manage", link: KHKS_MANAGE_LINK(k8s.id), display: !k8s?.is_deployed  && "none"},
                                    {label: "View nodes", link: KHKS_MANAGE_LINK(k8s.id)+'?act=nodes', display: !k8s?.is_deployed  && "none"},
                                    {label: "Delete", case: "onDelete", display: is('Deploying') && "none"},
                                ]}
                                server={k8s}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex justify-between pt-2 2xl:pt-4 text-sm 2xl:text-base'>
                    <div className=''>
                        <span className='font-bold text-primary'>
                        {k8s?.is_deployed && nodeMaster?.VpsStatus === "up" ? "Running" : nodeMaster?.VpsStatus === "down" ? "Stopped" : nodeMaster?.VpsStatus}
                        </span>
                    </div>
                    <div className=''>
                        <p className='text-right'>
                            <span className="font-bold text-primary">High Availability</span> <br/>
                            <span>{k8s?.isHA ? "Enabled": "Not enabled"}</span> <br/>
                            <span>{nodeMaster?.location?.name || "Montreal, Zone A"}</span>  
                        </p>
                    </div>
                </div>
            </div>
        </CardServiceLayout>
    )

}



export function MiniCarK8s({k8s, onAnimate, kube}) {
    const navigate = useNavigate()

    const masterCluster = k8s?.clusters?.find(cluster => cluster?.isPrimaryInstance)
    let totalNode = [];
    for (var i = 0; i < k8s?.clusters?.length; i++) {
        const instances = k8s?.clusters[i]?.instances
        totalNode = totalNode.concat(instances)
    }
	const nodeMaster = masterCluster && masterCluster.instances?.find(inst => !!inst.isKubeMaster && !!inst.isPrimarykubeMaster)

	// const totalCPUArr = totalNode && totalNode?.map(inst => inst?.type?.Cpus).reduce((a, b) => a + b, 0)
	// const totalRAMArr = totalNode && totalNode?.map(inst => inst?.type?.RamGb).reduce((a, b) => a + b, 0)
	// const totalSSDArr = totalNode && totalNode?.map(inst => inst?.type?.DiskGb).reduce((a, b) => a + b, 0)
	
    const is = (state) => nodeMaster?.VpsStatus === state
    

	

 

    return(
        <MiniCardServiceLayout grayScale={!k8s?.is_deployed} server={k8s}>
            <div className='flex justify-between items-start'>
                <div className='flex gap-2'>
                    <img 
                        src={k8sImage} 
                        alt="" 
                        className='2xl:w-14 2xl:h-14 w-10 h-10' 
                    />
                    <div>
                        <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>
                            {masterCluster?.name}
                        </span> 
                        <br/>
                        <span className="2xl:text-sm font-semibold inline-flex text-xs">
                            High Availability
                            &nbsp;
                            {k8s?.isHA ?
                                <CheckCircleFilled className="text-primary" />
                                :
                                <CloseCircleFilled className="text-gray" />
                            }
                        </span>
                    </div>
                </div> 
                <div className='flex flex-row-reverse gap-[1px]  justify-end justify-end'>
                    <ServiceOptions
                        options={[
                            {label: "Download config", cunstomFun: () => !!k8s?.is_deployed && Utils.createK8SFile(k8s), display: !true && "none"},
                            {label: "Manage", link: KHKS_MANAGE_LINK(k8s.id), display: !k8s?.is_deployed  && "none"},
                            {label: "View nodes", link: KHKS_MANAGE_LINK(k8s.id)+'?act=nodes', display: !k8s?.is_deployed  && "none"},
                            {label: "Delete", case: "onDelete", display: is('Deploying') && "none"},
                        ]}
                        server={k8s}
                    />
                    <Tooltip title={!!k8s?.is_deployed ? "Go to your khks":" "}>
                        <ExtLink 
                            href={!!k8s?.is_deployed ? "https://"+nodeMaster?.OpenstackInstanceExtIp+":6443" : "javascript:;"}
                            icon={<LinkOutlined className=" text-sm text-[#fff]" />}
                            className="bg-primary py-[3px] px-[6px] rounded flex items-center justify-center"
                        />

                    </Tooltip>
                </div>
            </div>
        </MiniCardServiceLayout>
    )
}



