import { ArrowRightOutlined, LinkOutlined } from '@ant-design/icons';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { Tooltip } from "antd"
import { BsFillTerminalFill } from "react-icons/bs";
import { MdMoreVert } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { ExtLink } from 'components/Bloc';
import { CONSOLE_LINK } from 'constants/link';
import { save_deletable_service } from 'reducer/action/coreActions';

import { Reboot, Start, Stop } from "../components/Modals";

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Spiner } from 'components/Bloc';

export function ServiceOptions({server, options}){
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const work = (arg) => {
        switch (arg) {
            case "onStop":
                window.$("#Stop"+server?.Id).modal('show')
                break;
            case "onReboot":
                window.$("#Reboot"+server?.Id).modal('show')
                break;
            case "onStart":
                window.$("#Start"+server?.Id).modal('show')
                break;
            case "onDelete":
                //Dispatch delete boolean
                dispatch(save_deletable_service(server))
                break;
            case "onConsole":
                navigate(CONSOLE_LINK(server?.Id))
                break;
            default:
                break;
        }
    }
    return(
        <Menu  
            menuClassName="text-sm dark:text-darkTextColor dark:bg-black" 
            menuButton={()=><MenuButton>
                    <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
                </MenuButton>
            } 
            transition
        >
            {
                options && options.map((opt, j) => (
                    <MenuItem
                        style={{display: opt.display}} 
                        key={j}
                        className={`font-semibold dark:hover:bg-darkSiderbar ${opt.case==="onDelete"&&"text-red-700"}`} 
                        onClick={()=>{
                            if(typeof opt.cunstomFun === "function") return opt.cunstomFun()
                            if(typeof opt.link === "string") return navigate(opt.link)
                            else return work(opt.case)
                        }}
                    >   
                        {
                            opt?.href ?
                            <ExtLink 
                                href={"http://"+opt.href}
                                text={opt.label}
                            />
                            :
                            opt.label
                        }
                    </MenuItem>
                ))
            }
        </Menu>
    )
}

export function CardServiceLayout({className, children, server, grayScale}){

    return(
        <div
            className={
                `
                    2xl:py-6 
                    py-3
                    ${(server?.VpsStatus === "Deploying" || grayScale ) && " grayscale "}  
                    server 
                    bg-grey 
                    block  
                    rounded-lg 
                    2xl:px-6 
                    px-4
                    shadow-lg 
                    dark:bg-bgPagedark 
                    dark:text-darkTextColor
                    //2xl:max-w-[450px]
                    //max-w-[350px]
                    ${className}
                `
            } 
        >
            <Reboot id={"Reboot"+server?.Id} server={server} />
            <Stop  id={"Stop"+server?.Id} server={server} />
            <Start  id={"Start"+server?.Id} server={server} />
            <div>
                {children}
            </div>
        </div>
    )
}

export function MiniCardServiceLayout({className, children, server, grayScale}){
    return(
        <div 
            className={
            `
                ${(server?.VpsStatus === "Deploying" || grayScale) ? " grayscale " :  ""}  
                server 
                bg-grey 
                block  
                p-2
                2xl:w-[270px]
                w-[240px]
                rounded-lg  
                shadow-lg 
                dark:bg-bgPagedark 
                dark:text-darkTextColor
                ${className || ""}
            `}
        >
            <Reboot id={"Reboot"+server?.Id} server={server} />
            <Stop  id={"Stop"+server?.Id} server={server} />
            <Start  id={"Start"+server?.Id} server={server} />
            <div>
                {children}
            </div>
        </div>
    )
}

const ArrowUp = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="0.8em" height="0.8em" viewBox="0 0 20 20">
        <path fill="currentColor" fill="#fff" d="M8 3.75A.75.75 0 0 1 8.75 3h7.5a.75.75 0 0 1 .75.75v7.5a.75.75 0 1 1-1.5 0V5.56L4.28 16.78a.75.75 0 0 1-1.06-1.06L14.44 4.5H8.75A.75.75 0 0 1 8 3.75"></path>
    </svg>
)

export function MiniCard({server, imgSrc, firstText, IpOrLocationText, options, withConsoleLink, withExternalLink, withInternalLink, functionLink, tooltipText, is, loanding, imgClass}){
    return(
        <MiniCardServiceLayout server={server}>
            <div className='flex justify-between items-start'>
                <div className='flex 2xl:gap-4 gap-2'>
                    <img src={imgSrc} alt="service moini ciard icon" className={` ${imgClass ? imgClass : ' w-14 h-14' }`} />
                    <div>
                        <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>{firstText}</span> <br/>
                        <span className="2xl:text-sm font-semibold text-xs">{IpOrLocationText}</span>
                    </div> 
                </div>
                <div className='flex flex-row-reverse gap-[1px]  justify-end justify-end'>
                    <ServiceOptions
                        options={options}
                        server={server}
                    />
                    {loanding ?
                        <Spiner fontSize={18} />
                        :
                        <Tooltip title={tooltipText}> 
                            {withConsoleLink ?  
                                <BsFillTerminalFill  
                                    className='text-2xl dark:text-darkTextColor text-primary cursor-pointer'
                                    onClick={() => functionLink()}
                                /> 
                                : 
                                (withInternalLink || withExternalLink) ?
                                <span  
                                    className="bg-primary cursor-pointer px-[6px] rounded flex items-center justify-center"
                                    onClick={() => functionLink()}
                                >   
                                    {withInternalLink ?  <ArrowRightOutlined className="text-xs text-[#fff]"/> : null}
                                    {withExternalLink ?  
                                        <ExtLink 
                                            href={is('up') ? "http://"+server.domain : "#"}
                                            icon={<ArrowUp className=" -srotate-45  text-[#fff]" />}
                                            className=""
                                            noHover
                                        /> 
                                        :
                                        null
                                    }
                                    
                                </span>
                                :
                                null
                            }
                        </Tooltip>
                    }
                </div>
            </div>
        </MiniCardServiceLayout>
    )
}