import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import OrderKsSub from 'pages/infra/kssub/OrderKsSub';

import Sidebar from './components/Sidebar';
import UserMgt from './lib/user_managment';
import UTILITY from './lib/utility_service'
import VPC from './lib/vpc';
import AccountVerification from './pages/auth/AccountVerification';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import RequestResetPassword from './pages/auth/RequestResetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import SignUpMethod from './pages/auth/SignUpMethod';
import TwoFaVerication from './pages/auth/TwoFaVerication';
import Bill from './pages/bill/Bill';
import CommingSoonPage from './pages/CommingSoonPage';
import Dashboard from './pages/Dashboard';
import KsKubHyBrManage from './pages/k8/kskubhybr/KsKubHyBrManage';
import ListKsKubHyBr from './pages/k8/kskubhybr/ListKsKubHyBr';
// import Vullify from './pages/vullify/Vullify';
import KskubhybrNew from './pages/k8/KskubhybrNew';
import KwafKSProxy from './pages/kwaf/KwafKSProxy';
//KWAF
// import Vullify from './pages/vullify/Vullify';
import KwafNew from './pages/kwaf/KwafNew';
import ListItemWrapper from './pages/ListItemWrapper';
import DeployKsIb from './pages/network/ksIb/DeployKsIb';
// import KsLbList from './pages/network/ksIb/KsLbList';
import KSLbManange from './pages/network/ksIb/KSLbManange';
//NETWORKING
import NewKsIb from './pages/network/ksIb/NewKsIb';
import ListVpc from './pages/network/vpc/ListVpc';
import NewVpc from './pages/network/vpc/NewVpc';
import VpcManage from './pages/network/vpc/VpcManage';
import PageNotFound from './pages/notfound/PageNotFound';
import FreeTryKps from './pages/order/FreeTryKps';
import FreeTryVps from './pages/order/FreeTryVps';
import Order from './pages/order/Order';
import Profile from './pages/profile/Profile';
import Reward from './pages/reward/Reward';
import AddVolumeStorage from './pages/storage/volume/AddVolumeStorage';
//STORAGE
import ListVolumeStorage from './pages/storage/volume/ListVolumeStorage';
import Forum from './pages/support/Forum';
import NewTicket from './pages/support/ticket/NewTicket';
import Ticket from './pages/support/ticket/Ticket';
import Wiki from './pages/support/Wiki';
import AddServer from './pages/vps/AddServer';
import ManageVps from './pages/vps/ManageVps';
import VpsConsole from './pages/vps/VpsConsole';
import AddVpsMini from './pages/vps/vpsMini/AddVpsMini';
import VpsMiniManage from './pages/vps/vpsMini/VpsMiniManage';
import VpsOptions from './pages/vps/VpsOptions';
import AddVpsSec from './pages/vps/vpsSec/AddVpsSec';
import VpsSec from './pages/vps/vpsSec/VpsSec';
import AddVpsSecMini from './pages/vps/vpsSecMini/AddVpsSecMini';
import KProxyWrap from './pages/vullify/KProxyWrap';
import VullifyConnect from './pages/vullify/VullifyConnect';
import VullifyListScan from './pages/vullify/VullifyListScan';
// import AddVpsSec from './pages/vps/vpsSec/AddVpsSec';
import VullifyNew from './pages/vullify/VullifyNew';
import VullifyReportDetailled from './pages/vullify/VullifyReportDetailled';
import VullifyTarget from './pages/vullify/VullifyTarget';
import AddWebApp from './pages/webApp/AddWebApp';
import AddWebAppServer from './pages/webApp/AddWebAppServer';
import AppDetails from './pages/webApp/AppDetails';
import CaptainAppConfig from './pages/webApp/config';
import AddKpsMini from './pages/webApp/kpsMini/AddKpsMini';
import WebApp from './pages/webApp/WebApp';

const routes = [
    {component: () => <Dashboard />, link: "/", exact: true, dashbord: true},
    // static vps
    {component: () => <ListItemWrapper source="vps" />, link: "/compute/vps", exact: true},
    {component: () => <CommingSoonPage />, link: "/compute/kc2", exact: true},
    {component: () => <ManageVps />, link: "/compute/vps/manage/:id"},
    {component: () => <VpsOptions />, link: "/compute/vps/connect/:id"},
    // {component: () => <AddServer />, link: "/compute/vps/deploy/:isTrial?", exact: true},
    // {component: () => <FreeVps />, link: "/compute/vps/deploy/trial", exact: true},
    {component: () => <VpsConsole />, link: "/compute/vps/:id/console", exact: true},
    // {component: () => <VpsPromo />, link: "/compute/vps/deploy/promo", exact: true},
    {component: () => <FreeTryVps />, link: "/compute/vps/free-deploy", exact: true},

    {component: () => <VpsSec />, link: "/compute/vpssec", exact: true},
    {component: () => <AddVpsSec />, link: "/compute/vpssec/deploy", exact: true},

    {component: () => <ListItemWrapper  source="vpsmini" />, link: "/compute/vpsmini", exact: true},
    {component: () => <VpsMiniManage />, link: "/compute/vpsmini/manage/:id", exact: true},
    // {component: () => <TrialVpsMini />, link: "/compute/vpsmini/deploy/trial", exact: true},
    // {component: () => <AddVpsMini />, link: "/compute/vpsmini/deploy", exact: true},
    {component: () => <ListItemWrapper source="vpssecmini" />, link: "/compute/vpssecmini/", exact: true},
    // {component: () => <AddVpsSecMini />, link: "/compute/vpssecmini/deploy", exact: true},

    //Kubernetes
    {component: () => <CommingSoonPage />, link: "/kubernetes/kskub" , exact: true},
    {component: () => <OrderKsSub />, link: "/kubernetes/kskub/deploy" , exact: true},
    {component: () => <CommingSoonPage />, link: "/kubernetes/kskubmini" , exact: true},
    {component: () => <ListKsKubHyBr />, link: "/kubernetes/kskubhybr" , exact: true},
    {component: () => <KsKubHyBrManage />, link: "/kubernetes/kskubhybr/manage/:id" , exact: true},
    {component: () => <KskubhybrNew />, link: "/kubernetes/kskubhybr/deploy" , exact: true},
    {component: () => <CommingSoonPage />, link: "/kubernetes/kskubops" , exact: true},
    //Infrastructure
    {component: () => <CommingSoonPage />, link: "/infrastructure/infrafusion" , exact: true},
    {component: () => <OrderKsSub />, link: "/infrastructure/infrafusion/deploy" , exact: true},
    {component: () => <CommingSoonPage />, link: "/infrastructure/infrafusionmini" , exact: true},
    {component: () => <CommingSoonPage />, link: "/infrastructure/infrafusionbmetal" , exact: true},
    //KS-OPS
    {component: () => <CommingSoonPage />, link: "/infrastructure/ksops" , exact: true},
    {component: () => <CommingSoonPage />, link: "/infrastructure/baremetal" , exact: true},

    //STORAGE
    {component: () => <ListVolumeStorage />, link: "/storage/volume" , exact: true},
    {component: () => <AddVolumeStorage />, link: "/storage/volume/deploy" , exact: true},


    //Networking
    {component: () => <ListItemWrapper  source="kslb" />, link: "/networking/kslb" , exact: true},
    {component: () => <DeployKsIb />, link: "/networking/kslb/deploy" , exact: true},
    {component: () => <KSLbManange />, link: "/networking/kslb/:id/manage" , exact: true},
    {component: () => <NewKsIb />, link: "/networking/kslb/:id/load-balance/new" , exact: true},
    
    //VPC
    {component: () => <NewVpc />, link: "/networking/vpc/deploy" , exact: true},
    {component: () => <ListVpc />, link: "/networking/vpc" , exact: true},
    {component: () => <VpcManage />, link: "/networking/vpc/:id/manage" , exact: true},

    //Security
    {component: () => <ListItemWrapper  source="kwaf" />, link: "/security/kwaf" , exact: true},
    {component: () => <KwafNew />, link: "/security/kwaf/deploy" , exact: true},
    {component: () => <KwafKSProxy />, link: "/security/kwaf/:id/connect" , exact: true},

    //knterconnectks
    {component: () => <CommingSoonPage />, link: "/networking/knterconnectks" , exact: true},
    {component: () => <CommingSoonPage />, link: "/networking/knterconnectks/deploy" , exact: true},

    //Security
    {component: () => <ListItemWrapper  source="vullify" />, link: "/security/vullify" , exact: true},
    {component: () => <VullifyNew />, link: "/security/vullify/deploy" , exact: true},
    {component: () => <VullifyConnect />, link: "/security/vullify/:id/connect" , exact: true},
    {component: () => <KProxyWrap />, link: "/security/vullify/:id/connect/ksproxy" , exact: true},
    {component: () => <VullifyTarget />, link: "/security/vullify/:id/connect/target" , exact: true},
    {component: () => <VullifyListScan />, link: "/security/vullify/:id/connect/target/:targetId" , exact: true},
    {component: () => <VullifyReportDetailled />, link: "/security/vullify/:id/connect/target/:targetId/:idScan" , exact: true},
    // {component: () => <VullifyAdd />, link: "/security/vullify" , exact: true},

    //billing rouites 
    {component: () => <Bill />, link: "/billing", exact: true},

    // Ticket
    {component: () => <Ticket />, link: "/ticket", exact: true},
    {component: () => <NewTicket />, link: "/new-ticket", exact: true},

    // support
    {component: () => <Forum />, link: "/forum", exact: true},
    {component: () => <Wiki />, link: "/wiki", exact: true},


    // //KPs
    {component: () => <AddWebApp />, link: "/app-platform/kps/:server_id/add" , exact: true},
    // {component: () => <AddWebAppServer />, link: "/app-platform/kps/deploy" , exact: true},
    {component: () => <WebApp />, link: "/app-platform/kps/:server_id" , exact: true},
    // {component: () => <KpsPromo />, link: "/app-platform/kps/promo" , exact: true},
    {component: () => <AppDetails />, link: "/app-platform/kps/:server_id/:name/details" , exact: true},
    {component: () => <CaptainAppConfig />, link: "/app-platform/kps/:server_id/:appName/config" , exact: true},
    {component: () => <ListItemWrapper source="kps" />, link: "/app-platform/kps" , exact: true},
    {component: () => <CommingSoonPage />, link: "/app-platform/kpsdev" , exact: true},
    {component: () => <CommingSoonPage />, link: "/app-platform/kpssec" , exact: true},
    // {component: () => <FreeWebAppServer />, link: "/app-platform/kps/deploy/trial" , exact: true},

    {component: () => <AddKpsMini />, link: "/app-platform/kpsmini/deploy" , exact: true},
    {component: () => <FreeTryKps />, link: "/app-platform/kps/free-deploy" , exact: true},

    // Order 
    {component: () => <Order />, link: "/order" , exact: true},

    // // Reward
    {component: () => <Reward />, link: "/reward" , exact: true},

    // // profile
    {component: () => <Profile />, link: "/profile" , exact: true},

]


const SidebarLayout = () => {
    useEffect(()=>{
        (async ()=>{
             await UserMgt.get_user_vps()
             await UserMgt.get_vps_types()
             UTILITY.fetchPaymentMethod()
             VPC.getAll()
         })()
     }, [])
    return(
        <div className="h-fit">
            <Sidebar />
            <div className="md:ml-60 2xl:ml-72 dark:bg-black pt-20 h-screen overflow-y-auto ">
                <Outlet />
            </div>
        </div>
    );
}

// const vpsOrderRoute = [
//     {
//         path: "vps",
//         canTrial: true
//     },
//     {
//         path: "vpsmini",
//         canTrial: false
//     },
//     {
//         path: "vpssec",
//         canTrial: true
//     },
//     {
//         path: "vpssecmini",
//         canTrial: true
//     },
// ]

function ProtectedRoute({ children, dashbord }) {
    const user = useSelector(app => app.user?.user)
    const location = useLocation()
    const preser = window.localStorage.getItem('@user_first_service')

    if (!user) {
        return <Navigate to={"/login"} state={{ from: location }} replace />;
    }
    else if(user &&  preser && preser !== "" && preser != null && dashbord){
        window.localStorage.removeItem('@user_first_service')
        return <Navigate to={preser} state={{ from: location }} replace />; 
    }

    // if(user && window.$crisp){
    //     window.$crisp.push(["set", "user:email", [user?.EmailAddr]]);
    //     window.$crisp.push(["set", "user:nickname", [user?.FirstName]]);
    // }

    return children;
}


export default function AppRoute({loginForce}){   


    return(
        <Routes style={{zoom: 0.7}}>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/2-fac-check" element={<TwoFaVerication />} />
            <Route exact path="/registrations/new" element={<SignUpMethod />} />
            <Route exact path="/registrations/email" element={<Register />} />
            <Route exact path="/activate-account" element={<AccountVerification />} />
            <Route exact path="/forgot_password/new" element={<RequestResetPassword />} />
            <Route exact path="/password-reset" element={<ResetPassword />} />
            {!loginForce && 
                <Route element={<SidebarLayout />}>
                    {routes.map((r, i) => (              
                        <Route
                            key={i}
                            exact
                            state={"yeg"}
                            path={r.link}
                            element={!loginForce ? 
                                <ProtectedRoute dashbord={r.dashbord}>
                                    {r.component()}
                                </ProtectedRoute>
                                :
                                <Navigate to={"/login"}  />
                            }
                        />  
                    ))}

                    <Route
                        exact
                        path={"/compute/vps/deploy/"}
                    >  
                        <Route
                            index
                            element={
                                <ProtectedRoute>
                                    <AddServer />
                                </ProtectedRoute>
                            }
                        />  
                        <Route
                            path='trial'
                            element={
                                <ProtectedRoute>
                                    <AddServer isTrial />
                                </ProtectedRoute>
                            }
                        />  
                        <Route
                            path='promo'
                            element={
                                <ProtectedRoute>
                                    <AddServer isPromo />
                                </ProtectedRoute>
                            }
                        />  
                    </Route>
                    <Route
                        exact
                        path={"/compute/vpssecmini/deploy/"}
                    >  
                        <Route
                            index
                            element={
                                <ProtectedRoute>
                                    <AddVpsSecMini />
                                </ProtectedRoute>
                            }
                        />  
                        <Route
                            path='trial'
                            element={
                                <ProtectedRoute>
                                    <AddVpsSecMini isTrial />
                                </ProtectedRoute>
                            }
                        />  
                        <Route
                            path='promo'
                            element={
                                <ProtectedRoute>
                                    <AddVpsSecMini isPromo />
                                </ProtectedRoute>
                            }
                        />  
                    </Route>
                    <Route
                        exact
                        path={"/compute/vpsmini/deploy/"}
                    >  
                        <Route
                            index
                            element={
                                <ProtectedRoute>
                                    <AddVpsMini />
                                </ProtectedRoute>
                            }
                        />  
                        <Route
                            path='trial'
                            element={
                                <ProtectedRoute>
                                    <AddVpsMini isTrial />
                                </ProtectedRoute>
                            }
                        />  
                        {/* <Route
                            path='promo'
                            element={
                                <ProtectedRoute>
                                    <AddVpsMini isPromo />
                                </ProtectedRoute>
                            }
                        />   */}
                    </Route>
                    <Route
                        exact
                        path={"/app-platform/kps/deploy/"}
                    >  
                        <Route
                            index
                            element={
                                <ProtectedRoute>
                                    <AddWebAppServer />
                                </ProtectedRoute>
                            }
                        />  
                        <Route
                            path='trial'
                            element={
                                <ProtectedRoute>
                                    <AddWebAppServer isTrial />
                                </ProtectedRoute>
                            }
                        />  
                        <Route
                            path='promo'
                            element={
                                <ProtectedRoute>
                                    <AddWebAppServer isPromo />
                                </ProtectedRoute>
                            }
                        />  
                    </Route>
                    <Route exact path="*" element={!loginForce ?<CommingSoonPage /> : <Navigate to={"/login"}  />} />
                </Route>
            }
            
            <Route exact path="*" element={!loginForce ?<CommingSoonPage /> : <Navigate to={"/login"}  />} />
        </Routes>
    )
}








           


