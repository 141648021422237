import { Button } from 'antd';
import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';

import { ExtLink } from 'components/Bloc';

import volume from "../../../assets/img/storage/volume.png"
import { Spiner } from '../../../components/Bloc'
import { ModalIncreaseSize, TableFunction } from '../../../components/volume/VolumeSection'
import STORAGE from '../../../lib/storage';
import { useSelector } from 'react-redux';

export default function ListVolumeStorage(){
	const navigate = useNavigate()
	const [loadingData, setLoading] = useState(false)
	const {list} = useSelector(state => state.volume)
	const [editedVolume, setEditVolume] = useState(null)
	
	async function listStorageVolume(){
		setLoading(true)
        await STORAGE.list()
		setLoading(false)
    }


	useEffect(()=>{ 
        !list && listStorageVolume() 
    }, [])


	return (
		<div className="px-3 md:px-8 pb-10">

			<ModalIncreaseSize 
				open={editedVolume} 
				setOpen={setEditVolume} 
				vlume={editedVolume} 
				reloadVolumeList={listStorageVolume} 
			/>
			{!loadingData ?
				<div>
					<div>
						<div className="flex justify-between items-end text-primary mt-5" >
							<span className="font-semibold text-xl xl:text-2xl 2xl:text-3xl">Volumes</span>

							<span className="flex gap-3 justify-end items-center">
								<ExtLink 
									href={"javascript:;"}
									isLean
								/>
								{list && list.length ?
									<Button 
										className="mx-auto w-32 font-semibold h-10 2xl:h-12 " 
										style={{borderRadius: "0px"}} 
										type="primary"
										onClick={
											()=>navigate('/storage/volume/deploy')
										}
									>
										Create Volume
									</Button>
									:
									null
								}
							</span>
						</div>
							<div className="mt-5">
								{list && list.length ?
									<TableFunction 
										setEditVolume={setEditVolume}  
										data={list.sort((v1, v2) => v2.id - v1.id)}
										reloadVolumeList={listStorageVolume}
									/>
								     :
									<div>

										<div className="border border-primary border-1 py-8 flex flex-col justify-center">
											<img src={volume} className="w-28 mx-auto" />
											<p className="text-center py-5">
												<span className="font-semibold text-base 2xl:text-xl">
													Look like you don't have any blocks storage volume
												</span>	
												<br/>
												<span className="text-sm 2xl:text-base">
													Volume are heighly available units of block storage that you can attach to
													our server to increase its space.
												</span>	
											</p>
											<Button 
												className="mx-auto w-32 font-semibold h-10 2xl:h-12 " 
												style={{borderRadius: "0px"}} 
												type="primary"
												onClick={
													()=>navigate('/storage/volume/deploy')
												}
											>
												Add Volume
											</Button>
										</div>
									</div>
								}
								<div className="mt-5 border border-1 border-gray-200 bg-neutral-50 p-3">
									<span className="font-semibold text-base 2xl:text-xl">
										Block storage basics
									</span>	
									<div className="flex flex-wrap mt-3 justify-between">	
										<div>
											<p className="text-sm">
												<ExtLink 
													href={"#"}
													text="Overview"
												/>
												<br/>	
												Discover block storage and what you can do with volume.
											</p> 	
										</div>	
										<div>
											<p className="text-sm">
												<ExtLink 
													href={"#"}
													text="API docs"
												/>
												<br/>	
												Use storage volume via the KeepSec API.
											</p>	
										</div>	
										<div>
											<p className="text-sm">
												<ExtLink 
													href={"#"}
													text="Tell us what you think"
												/>
												<br/>	
												Submit your feelback on block storage.
											</p>	
										</div>	
									</div>	
								</div>

							</div>
					</div>
				</div>
				:
				<div className='flex justify-center'>
	                <Spiner fontSize={40} />
	            </div>
	        }
		</div>
	)
}