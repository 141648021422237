import { Tooltip } from 'antd';
import { Alert, Button, Modal, Space } from 'antd';
import React from 'react'
import { useEffect, useState } from 'react';
import { EditText, EditTextarea } from 'react-edit-text';
import { TabPanel, useTabs } from "react-headless-tabs";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import dark from '../../assets/img/dark.png'
import light from '../../assets/img/light.png'
import { Fire, Toast } from '../../components/Notify';
import { TabSelector } from "../../components/TabSelector";
import UserAPI from "../../lib/keepsec_auth_service"
import UserMgt from "../../lib/user_managment"
import { ThemeContext } from '../../theme/ThemeContext';
import TwoFactorSetup from './TwoFactorSetup'

import 'react-edit-text/dist/index.css';

function DisableTwoFa({open, setOpen, onFetchProfile}){
    const {messageApi} = useSelector(app => app.core )
    const [ongoing, setOnGoing] = useState(false)
    async function disabledTwoFa(){
        setOnGoing(true)
        const result = await UserAPI.disableTwoFa()
        setOnGoing(false)
        if(result?.error){
            messageApi.open({
                type: 'error',
                content: 'An error occurs, please try again later or contact us.',
            });
        }
        setOpen(false)
        messageApi.open({
            type: 'success',
            content: 'Two-factor auth has been disabled in your account.',
        });
        onFetchProfile()
    }
    return(
        <Modal
            destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={600}
            open={open}
        >
            <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            Disable two-factor authentification
                        </h4>
                        {/*<p className='text-sm xl:text-base'>
                            Pick new size to increase. 
                        </p>*/}
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                    <div className="w-full text-sm xl:text-base font-semibold px-6 py-10">                       
                        <p>
                            This reduces the security level of your account and is not 
                            recommended.
                        </p>
                        <p className="mt-2">
                            Are you sure you want to continue?
                        </p>
                    </div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
                        <Button size="large" 
                            onClick={() => {
                                // setData({})
                                setOpen(false); 
                            }}
                        >
                            No, Cancel
                        </Button>
                        <Button 
                            className="bg-red-500" 
                            danger
                            loading={ongoing}
                            style={{color: "white"}}
                            size="large" 
                            // type="primary"  
                            onClick={disabledTwoFa}
                        >
                            Yes, Disable 2FA
                        </Button>
                    
                </div>
            </div>
        </Modal>
    )
}
function TwoFactorCard({user, onFetchProfile, codeRemainArr}){
    const [open, setTwoFaModalVisible] = useState(false)

    return(
        <div>
            <div className="flex gap-2 flex-wrap items-end text-gray-700">
                <div>
                    <p className="xl:text-base text-sm font-semibold">
                        DEFAULT
                    </p>
                    <div className="py-2 px-4 mt-2 border-2 rounded-md border-gray-300 w-[300px] shadow-lg">
                        <p className="font-bold  text-sm xl:text-base">
                            {
                                user?.twoFaMethod === "app" ?
                                "Authentificator App"
                                :
                                "SMS Authentificator"
                            }
                        </p>
                    
                        <p className="xl:text-base mt-2 text-sm font-semibold">
                            Configured
                        </p>
                    </div>
                </div>
                <div>
                    <p className="xl:text-base text-sm font-semibold">
                        BACKUP
                    </p>
                    <div className="py-2 px-4 mt-2 border-2 rounded-md border-gray-300 w-[300px] shadow-lg">
                        <p className="font-bold  text-sm xl:text-base">
                            Backup Codes
                        </p>
                        <p className="xl:text-base mt-2  text-sm font-semibold">
                            {codeRemainArr?.length} remaining
                        </p>
                    </div>
                </div>
                <div className="pb-3">
                    <Button 
                        className="w-32 font-semibold h-10 2xl:h-12 rounded" 
                        type="primary"
                        onClick={() =>setTwoFaModalVisible(true)}
                    >
                        Disable 2FA
                    </Button>
                </div>
            </div>
            <DisableTwoFa 
                open={open} 
                setOpen={setTwoFaModalVisible} 
                onFetchProfile={onFetchProfile} 
            />
        </div>
    )
}


export default function Profile() {
    const user2 = useSelector(app => app?.user?.user)
    const [user, setUser] = useState({})
    const [emailIsSending, setEmailIsSending] = useState(false)
    const [codeRemainArr, setCodeRemainTab] = useState([])

    const [resetPassObj, setResetPassObj] = React.useState({
        oldPassword: null,
        newPassword: null,
        newConfirmPassord: null
    });
    const navigate = useNavigate()
    const [resetPassOngoing, setResePassOngoing] = React.useState(false)
    const { theme, setTheme } = React.useContext(ThemeContext);

    const [selectedTab, setSelectedTab] = useTabs([
        "Profile",
        "Preferences",
        "Authentification",
        "Security"
    ]);
    


    useEffect(()=>{
        //UserMgt.get_user_payment() 
    }, [])

    async function onFetchProfile(){
        const res = await UserMgt.get_user_profile(user2?.Id) 
        setUser(res)

        const tab = res?.recovery2FaCode?.split("-")
        const finalTab = []
        for(let x = 0; x < tab?.length; x++){
          const subtab = tab[x].split(',')
          for(let j = 0; j < subtab.length; j++){
            finalTab.push(subtab[j])
          }
        }
        setCodeRemainTab(finalTab.filter(t => t !== ""))

    }
    useEffect(()=>{
        onFetchProfile()
    }, [])
    const handleChangeResetPassword = (e) => {
      const newResetObj = {
        ...resetPassObj,
        [e.target.name]: e.target.value
      }
      setResetPassObj(newResetObj)
    };


    async function OnSubmitResetPassword(){
        try{
            if( !resetPassObj.newPassword 
                || !resetPassObj.newConfirmPassord 
                ||  resetPassObj.newPassword !== resetPassObj.newConfirmPassord
                || !resetPassObj.oldPassword
            ){
               return Toast, Fire.fire({
                    icon: 'error',
                    title: "Please fill correctly all the fields!"
                })
            }
            setResePassOngoing(true)

            const results = await  UserAPI.reset_pass_when_login(resetPassObj)
            console.log('ressult reset', results, resetPassObj)
            if(!results || results?.status !== "success"){
                Toast.fire({
                    icon: 'error',
                    title: results.message || "Undefined error occurs, please contact us!"
                })
                setResePassOngoing(false)
            }else{ 

                Toast.fire({
                    icon: 'success',
                    title: results?.message
                })
                setResePassOngoing(false)
                return navigate('/')
                
                
            }
        }catch(e){
            console.error('OnError resetPass', e)
            setResePassOngoing(false)
            Toast.fire({
                icon: 'error',
                title: "Undefined error occurs, please contact us!"
            })
        }
    }
    const onUpdate2 = async (data) => {
        try {
            // return;
            const result = await UserMgt.on_update_profile(data)
            onFetchProfile()
        } catch (error) {
            console.log('error onUpdate2')
        }
    }
    const handleChange = (e, saved) => {
        console.log('user user user Chaneg', e)
        setUser({
            ...user,
            [e.name]: e.value
        });
      if(saved) return onUpdate2({[e.name]: e.value})
    };

    async function onConfirmEmail(){ 
        setEmailIsSending(true)
        try {
            const result = await UserMgt.reSendEmailVerification({EmailAddr: user?.EmailAddr, FirstName: user?.FirstName})
            console.log('result result result', result)
            Fire.fire({
                title: <strong>Good job!</strong>,
                html: <i>{result?.message}</i>,
                icon: 'success'
              })
        } catch (error) {
            console.log('onConfirm error', error)
        }
        setEmailIsSending(false)
    }

    return (
        <div>
            <div className=' dark:text-darkTextColor'>
                <div className="px-3 md:px-8 ">
                    <div className="pt-5">

                        <nav className="flex border-b border-gray-300 dark:border-gray-800">
                            <TabSelector
                              isActive={selectedTab === "Profile"}
                              onClick={() => setSelectedTab("Profile")}
                            >
                              Profile
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Preferences"}
                              onClick={() => setSelectedTab("Preferences")}
                            >
                              Preferences
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Authentification"}
                              onClick={() => setSelectedTab("Authentification")}
                            >
                              Authentification
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Security"}
                              onClick={() => setSelectedTab("Security")}
                            >
                              Security
                            </TabSelector>
                           
                          </nav>
                          <div className="pl-5 pt-4">
                            <TabPanel hidden={selectedTab !== "Profile"}>
                                <div className="pt-5 md:pr-20 w-full md:w-3/4">
                                    {/* {user && !user?.EmailConfirmed ?

                                        <Space
                                            direction="vertical"
                                            style={{
                                            width: '100%',
                                            paddingBottom: "15px"
                                            }}
                                        >
                                            <Alert
                                                message=" You have to confirm your email address first to be able to use KeepSec cloud services"
                                                type="warning"
                                                action={
                                                    <Space direction="vertical">
                                                    <Button onClick={onConfirmEmail} className='px-5' loading={emailIsSending} type="primary">
                                                        Yes confirm it
                                                    </Button>
                                                    </Space>
                                                }
                                                className='py-6'
                                            />
                                        </Space>
                                        :
                                        null
                                    } */}
                                    <React.Fragment>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    placeholder="First name"
                                                    name={"FirstName"} 
                                                    value={user?.FirstName}
                                                    type="text"
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                            <div className="w-full md:w-1/2 px-3">
                                                <EditComponent 
                                                    name={"LastName"} 
                                                    value={user?.LastName}
                                                    placeholder="Last name"
                                                    type="text"
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full   px-3 mb-6 md:mb-0">
                                                {/* <Tooltip title={
                                                        user?.EmailConfirmed ?
                                                        "Your email address is now confirmed, so you start using KeepSec cloud services":
                                                        "Please confirm your email first before using KeepSec cloud service "
                                                    }
                                                    className='w-full'
                                                > */}
                                                    <span>
                                                    <EditComponent 
                                                        name={"emailAddr"} 
                                                        placeholder="Email"
                                                        type="email"
                                                        value={user?.EmailAddr}
                                                        handleChange={handleChange}
                                                        readonly

                                                    /></span>
                                                {/* </Tooltip> */}
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"PhoneNumber"} 
                                                    value={user?.PhoneNumber}
                                                    placeholder="Phone number"
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"Company"} 
                                                    value={user?.Company}
                                                    placeholder="Company"
                                                    type="text"
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                        
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"Address"} 
                                                    placeholder="Address"
                                                    value={user?.Address}
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"Address2"} 
                                                    placeholder="Address 2"
                                                    value={user?.Address2}
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full md:w-3/5 px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"City"} 
                                                    placeholder="City"
                                                    value={user?.City}
                                                    type="text"
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                            <div className="w-full md:w-2/5 px-3">
                                                <EditComponent 
                                                    name={"ZipCode"} 
                                                    placeholder="Zip code"
                                                    type="text"
                                                    value={user?.ZipCode}
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                        </div>

                                        {/* <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"PhoneNumber"} 
                                                    value={user?.PhoneNumber}
                                                    placeholder="Phone number"
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div> */}

                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"Website"} 
                                                    value={user?.Website}
                                                    placeholder="Company website"
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                         <div className="flex flex-wrap -mx-3 ">
                                            <div className="w-full  px-3 mb-6 md:mb-8  text-gray-700">
                                                <div>
                                                    Describe the service(s) you are running with your KeepSec account
                                                </div>
                                                <EditTextarea
                                                    name='Description'
                                                    className="bg-grey dark:bg-bgPagedark"
                                                    style={{ fontSize: '16px', border: '1px solid #ccc' }}
                                                    onChange={(e)=>handleChange({name: e.target.name, value: e.target.value})}
                                                    onSave={(e)=>handleChange(e, true)}
                                                    inputClassName="
                                                        block
                                                        bg-white
                                                        hover:bg-ligth
                                                        tracking-wide
                                                        text-gray-700 text-xs
                                                        font-bold
                                                        mb-2
                                                        dark:bg-bgPagedark
                                                        dark:text-darkTextColor
                                                    "
                                                    //onSave={handleSave}
                                                    value={user?.Description}
                                                    placeholder='Description'
                                                  />
                                            </div>
                                        </div>

{/* 
                                        <div className="flex flex-wrap  mb-6">
                                            <button className=" text-center text-xl  w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                type="submit"
                                                // disabled={updateOngoing}
                                                onClick={onUpdate2}
                                            >
                                                 <span className='mx-auto flex justify-center gap-1 items-center'>
                                                    {resetPassOngoing?
                                                        <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        :
                                                        null
                                                    }
                                                    {resetPassOngoing ? " Ongoing" :  " Update profil"}
                                                </span>
                                                
                                            </button>
                                        </div> */}

                                    </React.Fragment>

                                </div>
                            </TabPanel> 

                          <TabPanel hidden={selectedTab !== "Preferences"}>
                               <div className=" pt-5">
                                    <h3 className="text-xl font-semibold">
                                        User Preferences
                                    </h3>
                                    
                                    <div>
                                        <div  
                                        onClick={() => setTheme('light' )}
                                        onMouseOver={() => {
                                                //setSelectedImage(image.id)
                                            }
                                        }  className={'w-48 mt-5 dark:bg-bgPagedark  ml-10  cursor-pointer rounded-xl ' +
                                            (true ? "hover:bg-white dark:hover:shadow-darkSiderbar   hover:shadow-2xl " : '') + "" + (true ? "bg-white shadow-xl" : 'bg-neutral-100')}>

                                            {(theme==='light') &&
                                                <svg className="div_check checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                                    <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                </svg>
                                            }

                                            <div className=' p-5 pb-2 text-center '>
                                                {/*<img src={light} alt="" className='w-24 h-20 mx-auto' />*/}
                                                <div className="lighBg w-24 h-20 mx-auto" />
                                                &nbsp;&nbsp;
                                                <p className='text-primary dark:text-darkTextColor font-semibold text-xl'>Light mode</p>
                                            </div>
                                        </div>

                                        <div 
                                        onClick={() => setTheme('dark')}
                                        onMouseOver={() => {
                                                //setSelectedImage(image.id)
                                            }
                                        }  className={'w-48 dark:bg-bgPagedark mt-5 ml-10 cursor-pointer rounded-xl ' +
                                            (true ? "hover:bg-white  hover:shadow-2xl dark:hover:shadow-darkSiderbar " : '') + "" + (false ? "bg-white shadow-xl" : 'bg-neutral-100')}>

                                            {(theme === "dark") &&
                                                <svg className="div_check checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                                    <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                </svg>
                                            }

                                            <div className=' p-5 pb-2 text-center'>
                                            <div className="darkBg w-24 h-20 mx-auto" />
                                                {/*<img src={dark} alt="" className='w-24 h-15 mx-auto' />*/}
                                                &nbsp;&nbsp;
                                                <p className='text-primary dark:text-darkTextColor font-semibold text-xl'>Dark mode</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex flex-wrap  mb-6 pt-5">
                                                <div className="w-full md:w-1/2  mb-6 md:mb-0">
                                                    <label
                                                        className="
                                                            block
                                                            
                                                            tracking-wide
                                                            text-gray-700 text-base
                                                            font-bold
                                                            mb-2
                                                        "
                                                        htmlFor="serv-tz"
                                                    >
                                                        Display Timezone
                                                    </label>
                                                    <select
                                                        className="
                                                            appearance-none
                                                            block
                                                            w-full
                                                            bg-gray-200
                                                            text-gray-700
                                                            border
                                                            rounded
                                                            py-3
                                                            px-4
                                                            mb-3
                                                            leading-tight
                                                            focus:outline-none focus:bg-white
                                                            dark:bg-bgPagedark
                                                            dark:text-darkTextColor
                                                        "
                                                        id="serv-tz"
                                                        //value={""}
                                                        name="server_name"
                                                        //onChange={handleChange}
                                                        required
                                                        //disabled={creating}
                                                        placeholder='Enther the current password'
                                                    >
                                                        <option>1</option>
                                                        <option>2</option>
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="flex flex-wrap ">
                                                <div className="w-full md:w-1/2  mb-6 md:mb-0">
                                                    <label
                                                        className="
                                                            block
                                                            
                                                            tracking-wide
                                                            text-gray-700 text-base
                                                            font-bold
                                                            mb-2
                                                        "
                                                        htmlFor="serv-df"
                                                    >
                                                       Date Format
                                                    </label>
                                                    <select
                                                        className="
                                                            appearance-none
                                                            block
                                                            w-full
                                                            bg-gray-200
                                                            text-gray-700
                                                            border
                                                            rounded
                                                            py-3
                                                            px-4
                                                            mb-3
                                                            leading-tight
                                                            dark:bg-bgPagedark
                                                            dark:text-darkTextColor
                                                            focus:outline-none focus:bg-white
                                                        "
                                                        id="serv-df"
                                                        //value={""}
                                                        name="server_name"
                                                        //onChange={handleChange}
                                                        required
                                                        //disabled={creating}
                                                    >
                                                        <option>
                                                         Choice 1
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>


                                            <div className="flex flex-wrap  mb-6">
                                                <button className=" mt-4 text-center text-xl  w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                    type="submit"
                                                    //disabled={creating}
                                                >
                                                     {" Update profile"}
                                                    
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          </TabPanel>

                           <TabPanel hidden={selectedTab !== "Authentification"}>
                                <div className=" pt-5">
                                    <h3 className="text-xl font-semibold">Two-Factor Authentification</h3>
                                    <div>
                                        <span>
                                            Keep your account secure by enabling two-factor authentification. <br/>
                                            Each time you log in you will be required to provide your password and a randomly generated access code
                                        </span>
                                        <br/>
                                        <br/>
                                        {user?.is2FaVerified ?
                                        <TwoFactorCard codeRemainArr={codeRemainArr} user={user} onFetchProfile={onFetchProfile} />
                                            :
                                        <TwoFactorSetup onFetchProfile={onFetchProfile} />
                                        }
                                         
                                        {/*Your email must be verified before proceeding with two-factor authentification*/}
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6 pt-5">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="
                                                    block
                                                    
                                                    tracking-wide
                                                    text-gray-700 text-base
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="oldPassword"
                                            >
                                                Current password
                                            </label>
                                            <input
                                                className="
                                                    appearance-none
                                                    block
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    leading-tight
                                                    focus:outline-none focus:bg-white
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                "
                                                id="oldPassword"
                                                value={resetPassObj?.oldPassword || ""}
                                                name="oldPassword"
                                                onChange={handleChangeResetPassword}
                                                required
                                                type='password'
                                                disabled={resetPassOngoing}
                                                placeholder='Enther the current password'
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="
                                                    block
                                                    
                                                    tracking-wide
                                                    text-gray-700 text-base
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="newPassword"
                                            >
                                                New password
                                            </label>
                                            <input
                                                className="
                                                    appearance-none
                                                    block
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                    leading-tight
                                                    focus:outline-none focus:bg-white
                                                "
                                                id="newPassword"
                                                value={resetPassObj?.newPassword || ""}
                                                name="newPassword"
                                                onChange={handleChangeResetPassword}
                                                required
                                                type='password'
                                                disabled={resetPassOngoing}
                                                placeholder='Enter the new password'
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="
                                                    block
                                                    
                                                    tracking-wide
                                                    text-gray-700 text-base
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="newConfirmPassord"
                                            >
                                                Confirm new password
                                            </label>
                                            <input
                                                className="
                                                    appearance-none
                                                    block
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    leading-tight
                                                    focus:outline-none focus:bg-white
                                                "
                                                id="newConfirmPassord"
                                                value={resetPassObj?.newConfirmPassord || ""}
                                                name="newConfirmPassord"
                                                onChange={handleChangeResetPassword}
                                                required
                                                type='password'
                                                disabled={resetPassOngoing}
                                                placeholder='Enter the new password confirmation'
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap  mb-6">
                                        <button className=" mt-4 text-center text-xl  w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                            type="submit"
                                            disabled={resetPassOngoing}
                                            onClick={OnSubmitResetPassword}
                                        >
                                            <span className='mx-auto flex justify-center gap-1 items-center'>
                                                {resetPassOngoing?
                                                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    :
                                                    null
                                                }
                                                {resetPassOngoing ? " Ongoing" :  " Change Password"}
                                            </span>
                                        </button>
                                    </div>
                                   
                                </div>
                          </TabPanel>
                         </div> 

                    </div>

            
                </div>
            </div>
        </div>
    );
}

function EditComponent(props){
    return(
        <div>
            {/*{props.value&&*/
               <div  className="
                                block
                                
                                tracking-wide
                                text-gray-700 text-base
                                font-bold
                                mb-2
                            "
                            >
                     {props.placeholder}
                </div>
            }            
            <div>
                <EditText
                    name={props.name}
                    type={props.type  || "text"}
                    style={{
                      // fontSize: '16px',
                      // border: '1px solid #011a43',
                      // marginBottom: '10px',
                      borderRadius: "5px",
                      height: "40px"
                    }}
                    className="bg-grey dark:bg-bgPagedark
                                                        

                    "
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(e) => props.handleChange({name: e.target.name, value: e.target.value})}
                    onSave={(e) => props.handleChange(e, true)}
                    // formatDisplayText={formatPrice}
                    readonly={props.readonly}
                    inputClassName="
                        appearance-none
                        block
                        w-full
                        dark:bg-bgPagedark
                        dark:text-darkTextColor
                        bg-gray-200
                        text-gray-700
                        border
                        rounded
                        py-3
                        px-4
                        mb-3
                        leading-tight
                        focus:outline-none focus:bg-white

                    "
                />
            </div>
        </div>
    )
}


