import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { KPS_MY_APP } from 'constants/link';
import { CardServiceLayout, MiniCard, ServiceOptions } from 'layouts/ServiceLayout'

import kpsLogoWhite from '../../assets/img/KPS-logo-56px-white.png'
import kpsLogo from '../../assets/img/KPS-logo-56px.png'
import  CAPTAIN  from '../../lib/libCaprover';
import { ThemeContext } from '../../theme/ThemeContext';
import { OkButton } from '../OkButton';
import { useSelector } from 'react-redux';

export function PlatformServerCard({server}) {
    const is = (s) => server?.VpsStatus === s
    const [loadingConnect, setLoadingConnect] = useState(false)
    const { theme } = React.useContext(ThemeContext);

    const navigate = useNavigate()

    const Clogin = async() => {
        setLoadingConnect(true)
        try{
            const res = await CAPTAIN.onCaproverLogin({...server})
            console.log(' login captrain resuklt', res)
            setLoadingConnect(false)
            if(res){
                return  navigate(KPS_MY_APP(server.Id))
            }
            if(server.caprover_token){
            }
        }catch(e){
            setLoadingConnect(false)
            console.log('error login', e)
        }
    }

    function DisplayDomain({ server }) {
        const getFormattedDomain = (domain) => {
            // Remove 'captain.' from the domain if it exists
            return domain.replace(/^captain\./, '');
        };
        const domain = server?.domain || server?.OpenstackInstanceName + ".kps.keepsec.cloud";
        const formattedDomain = getFormattedDomain(domain);

        return (
        <span className="text-xs xl:text-xs 2xl:text-base">
            {formattedDomain}
        </span>
        );
    }


    return (
        <CardServiceLayout server={server}>
            <div>
                <div className='border-b-2 dark:border-gray-800 pb-4 2xl:pb-8 pt-3'>
                    <div className='flex justify-between items-start'>
                        <div className='flex 2xl:gap-4 gap-2'>
                            <img src={theme !== "dark" ? kpsLogo : kpsLogoWhite} alt="" className='w-14 h-14' />
                            <div>
                                <span className='2xl:text-xl text-base font-semibold text-primary dark:text-darkTextColor'>{server?.DisplayName || server?.OpenstackInstanceName}</span> <br/>
                                <span className="2xl:text-base text-xs">{server?.OpenstackInstanceFlavor}</span>
                            </div> 
                        </div>
                        <div className='flex justify-end '>
                            <OkButton click fun={Clogin} title="Manage"   server={server} loanding={loadingConnect}/>
                            &nbsp;&nbsp;&nbsp;
                            <ServiceOptions
                                options={[
                                    {label: "Connect",  cunstomFun: ()=> Clogin(), display: !is('up') && "none"},
                                    {label: "Delete", case: "onDelete", display: is('Deploying') && "none"},
                                ]}
                                server={server}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex justify-between pt-2 2xl:pt-4 '>
                    <div className='text-sm 2xl:text-base'>
                        <span className="font-bold text-primary">
                            {
                                is("up")? 
                                "Running" : 
                                server?.VpsStatus
                            }
                        </span>
                    </div> 
                    <div className=''>
                        <p className='text-right'>
                            <DisplayDomain server={server}></DisplayDomain><br/>
                            <span className="text-sm 2xl:text-base">{server?.OpenstackInstanceExtIp}</span> <br/>
                            <span
                                className="text-sm 2xl:text-base">{server?.location?.name || "Montreal, Zone A"}</span>
                        </p>
                    </div>
                </div>
            </div>
        </CardServiceLayout>
    )

}

export function PlatformServerMiniCard({server}){
    const is = (s) => server?.VpsStatus === s
    const [loadingConnect, setLoadingConnect] = useState(false)
    const { theme } = React.useContext(ThemeContext);
    const { messageApi } = useSelector(app => app.core)
    const navigate = useNavigate()

    const Clogin = async() => {
        setLoadingConnect(true)
        try{
            const res = await CAPTAIN.onCaproverLogin({...server})
            setLoadingConnect(false)
            if(res){
                return  navigate(KPS_MY_APP(server.Id))
            }else{
                return messageApi.info('Your KPS server is not ready yet. please wait more minutes. ')
            }
        }catch(e){
            setLoadingConnect(false)
            console.log('error login', e)
        }
    }
  

    return(
        <MiniCard 
            server={server}
            is={is}
            imgSrc={theme !== "dark" ? kpsLogo : kpsLogoWhite}
            firstText={server?.DisplayName || server?.OpenstackInstanceName}
            IpOrLocationText={server?.location?.name || "Montreal, Zone A"}
            options={[
                {label: "Connect",  cunstomFun: ()=> Clogin(), display: !is('up') && "none"},
                {label: "Delete", case: "onDelete", display: is('Deploying') && "none"},
            ]}
            withInternalLink
            functionLink={() => Clogin()}
            tooltipText={"Go to list app page"}
            loanding={loadingConnect}
        />
    )
}