import moment from 'moment';
import * as yaml from 'yaml'

import { save_deletable_service } from 'reducer/action/coreActions';

import store from "../reducer"

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID

const zeroPad = i => ("" + (i)).padStart(2, '0')

const copyObject = (obj) => {
    return JSON.parse(JSON.stringify(obj))
} 

const convertComposeToService = (compose) => {
    // NOTE:
    // Port, replicas, env vars, volumes, and image are supplied through CapRover definition
    // network will be set to captain-overlay
    // restart_policy is not generally needed, by default docker services restart automatically
    // ----
    // Only parse parameters that are not from the aforementioned list.
    // The only useful parameter that we are parsing at the moment is hostname: https://github.com/caprover/caprover/issues/404

    const overrides = [] 
    overrides.push(parseHostname(compose))

    let mergedOverride = {}
    overrides.forEach((o) => {
        mergedOverride = mergeObjects(mergedOverride, o)
    })
    if (Object.keys(mergedOverride).length === 0) {
        return undefined
    }

    return yaml.stringify(mergedOverride)
}

function convertHexStringToUtf8(raw) {
        return !raw
            ? ''
            : decodeURIComponent(
                  raw
                      .substring(8, raw.length)
                      .replace(/\s+/g, '')
                      .replace(/[0-9a-f]{2}/g, '%$&')
              )
}

function parseHostname(compose) {
    const override = {}
    const hostname = !!compose.hostname ? `${compose.hostname}`.trim() : ''
    if (!!compose.hostname) {
        override.TaskTemplate = {
            ContainerSpec: {
                Hostname: hostname,
            },
        }
    }

    return override
}

function mergeObjects(object1, object2) {
    const newObject = object1 || {}
    object2 = object2 || {}

    Object.keys(object2).forEach((k) => {
        if (
            !newObject[k] ||
            Array.isArray(newObject[k]) ||
            Array.isArray(object2[k])
        ) {
            newObject[k] = object2[k]
        } else {
            if (
                typeof object2[k] === 'object' &&
                typeof newObject[k] === 'object'
            ) {
                newObject[k] = mergeObjects(newObject[k], object2[k])
            } else {
                newObject[k] = object2[k]
            }
        }
    })

    return newObject
}

const createRandomStringHex = (length)  => {
    let result = ''
    const characters = '0123456789abcdef'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }
    return result
}

const replaceAllGenRandomForOneClickApp = (inputString)  => {
    let matches

    while (
        (matches = /\$\$cap_gen_random_hex\((\d+)\)/g.exec(inputString)) &&
        matches.length === 2
    ) {
        const hexLength = Number(matches[1])
        if (hexLength > 256) {
            // capping out the maximum length to avoid unintentional problems
            inputString = inputString.replace(matches[0], '')
        } else {
            inputString = inputString.replace(
                matches[0],
                `${createRandomStringHex(hexLength)}`
            )
        }
    }

    return inputString
}

const getUrlParam = (name, url = window.location.href)  => {
    const name2 = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name2 + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null
    }
    if (!results[2]) {
      return ''
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
const isAdmin = (provUser)  => {
    const user = provUser || store.getState().user?.user
    return user?.role?.task_name==="admin"
}
const isDemo = (provUser)  => {
    const user = provUser || store.getState().user?.user
    return user?.role?.task_name==="demo"
}

function getAnsiColorRegex() {
        const pattern = [
            '[\\u001B\\u009B][[\\]()#;?]*(?:(?:(?:[a-zA-Z\\d]*(?:;[a-zA-Z\\d]*)*)?\\u0007)',
            '(?:(?:\\d{1,4}(?:;\\d{0,4})*)?[\\dA-PR-TZcf-ntqry=><~]))',
        ].join('|')

        return new RegExp(pattern, 'g')
}

function getUrlConsole(){
    return  [
        process.env.REACT_APP_CONSOLE_URL_A,
        process.env.REACT_APP_CONSOLE_URL_B,
        process.env.REACT_APP_CONSOLE_URL_C,
        process.env.REACT_APP_CONSOLE_URL_D
    ]
}

function testPassworsVps(str){ 
    // const pattern = /^(?=.*[A-Za-z0-9#$%&?@_§/}{.])[A-Za-z0-9!#$%&?@_§}{./\d]{6,}$/;
    const test = /\s/.test(str);
    return  test
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
function is(serv, status){
    return serv && serv.VpsStatus === status
}

function abbrNum(number, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);
  
    // Enumerate number abbreviations
    const abbrev = ["k", "m", "b", "t"];
  
    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {
  
      // Convert array index to "1000", "1000000", etc
      const size = Math.pow(10, (i + 1) * 3);
  
      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round(number * decPlaces / size) / decPlaces;
  
        // Handle special case where we round up to the next abbreviation
        if ((number == 1000) && (i < abbrev.length - 1)) {
          number = 1;
          i++;
        }
  
        // Add the letter for the abbreviation
        number += abbrev[i];
  
        // We are done... stop
        break;
      }
    }
  
    return number;
  }

function generateId(){
    return Math.random().toString(36).slice(2, 8)
}

function formatDate(date, format) {
    if (typeof date === 'number') {
        return moment.unix(date).format(format);
    }

    return moment(date).format(format);
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}
function createK8SFile(k8s, textFile){
    let content = k8s ? atob(k8s?.base64_kubeconfig) : null;
    let fileName = "kube_config.yaml"
    if(textFile){
        content = textFile
        fileName = "keepsec-2fa-recovery-code.txt"
    }
    const file = new File(["\ufeff"+content], fileName, {type: "text/plain:charset=UTF-8"});
    const url = window.URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
}
function onCopyText(text){
    return navigator.clipboard.writeText(text);
} 

function onNotifyCrisp(server){
    try {
        
        const product_deleted = [
            "product_deleted", {
                type: server?.service?.name,
                server_name: server?.DisplayName || server?.service?.name,
                name_package: server?.type?.Name || server?.service?.name,
                price_package: server?.type?.Price || 0,
                admin_deployed: !!server?.IsDeployedByAdmin,
            },
            "red"
        ]
        window.$crisp?.push(["set", "session:event", [[
            product_deleted
        ]]]);
    } catch (error) {
        console.error('Notify crisp deletion error ', error)
    }
}

function dispatchToDelete(service, rLink){
    store.dispatch(save_deletable_service(service, rLink))
}

function priceConvert(price){
    const { geoLocation } = store.getState().core
    return price * geoLocation?.excRate
}
function renderCurencyCode(){
    const { geoLocation } = store.getState().core
    return geoLocation?.currencyCode
}
async function deleteGlobal(server, objectParams={}){
    
}

const validateDNSorIP = (toBeTest) => {
    const dnsRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.([a-zA-Z0-9]{1,61}[a-zA-Z0-9]\.)*[a-zA-Z]{2,6}$/;
    const ipv4Regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
    const ipv6Regex = /^(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
    return dnsRegex.test(toBeTest) || ipv4Regex.test(toBeTest) || ipv6Regex.test(toBeTest);
}

const github_clien_id = process.env.REACT_APP_GIHUB_CLIENT_ID
const github_clien_secret = process.env.REACT_APP_GIHUB_CLIENT_SECRET
const gith_redirect_uri = "https://dash.keepsec.ca/login"
const gith_redirect_uri2 = "https://dash.keepsec.ca/registrations/new"

const google_clien_id = process.env.REACT_APP_GOOGLE_CLIENT_ID
const stripe_pub_key = process.env.REACT_APP_STIPPE_PUB_SECRET
export default{
    copyObject,
    replaceAllGenRandomForOneClickApp,
    convertComposeToService,
    getUrlParam,
    convertHexStringToUtf8,
    getAnsiColorRegex,
    getUrlConsole,
    testPassworsVps,
    PAYPAL_CLIENT_ID,
    github_clien_secret,
    github_clien_id,
    gith_redirect_uri,
    google_clien_id,
    gith_redirect_uri2,
    sleep,
    abbrNum,
    generateId,
    formatDate,
    capitalizeFirstLetter,
    randomIntFromInterval,
    createK8SFile,
    deleteGlobal,
    onCopyText,
    isAdmin,
    isDemo,
    stripe_pub_key,
    zeroPad,
    onNotifyCrisp,
    dispatchToDelete,
    priceConvert,
    renderCurencyCode,
    validateDNSorIP,
    is
}